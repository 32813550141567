<template>
  <div class="error__login" v-if="errorMsg !== ''">
    Error: {{errorMsg}}
  </div>
  <form @submit.prevent ="login()">
    <base-label input-type="email">Email:</base-label>
    <base-input v-model="email" name="email" mode="text" placeholder-text="Silahkan masukan email anda"></base-input>
    <base-label input-type="password">Password:</base-label>
    <base-input v-model="password" name="password" mode="password" placeholder-text="Silahkan masukan password anda" :style="{ 'margin-bottom': '20px' }" autocomplete="on"></base-input>
    <p><a href="#">Forgot Password</a></p>
    <base-button>Sign In</base-button>
  </form>
</template>

<script>
  export default {
    data() {
      return {
        email: '',
        password: '',
        url: '/users/login',
        method: 'POST',
        authenticate: false,
        errorMsg: '',
      }
    },
    inject: ['fetchData'],
    methods: {
      login() {
        this.fetchData(this.url, this.method,  JSON.stringify({email: this.email, password: this.password}), this.authenticate, null)
          .then((res) => {
            // document.cookie = "isAuthenticated=true"
            return res.json()
          })
          .then(data => {
            if(data.msg !== undefined) {
              this.errorMsg = data.msg
            } else {
              window.localStorage.setItem('_rToken', data.refreshToken)
              var now = new Date();
              var time = now.getTime();
              var expireTime = time + (1000 * 60 * 20) // 20 mins
              now.setTime(expireTime)
              document.cookie = `accessToken=${data.accessToken};expires=${now.toUTCString()};SameSite=None; Secure'`
              this.$router.push('/dashboard');
            }
            
          })
      }
    }
  }
</script>

<style>
  p {
    margin-bottom: 20px;
    text-align: right;
  }
  a {
    text-decoration: none;
  }
  .last-child {
    margin-bottom: 20px;
  }

  .error__login {
    color: red;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 1.5em;
    font-weight: 700;
  }
</style>