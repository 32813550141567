
<template>
  <div id="main">
    <router-view></router-view>
  </div>
</template>

<script>
import io from 'socket.io-client';
export default {
  name: 'App',
  data() {
    return {
      apiUrl: 'http://localhost:5000',
      isOnDuty: false,
      // socket: process.env.NODE_ENV === 'production' ? io(process.env.VUE_APP_PRODUCTION_SOCKET_ENDPOINT) : io(process.env.VUE_APP_SOCKET_ENDPOINT)
      // socket: io(process.env.NODE_ENV === 'development' ? process.env.VUE_APP_SOCKET_ENDPOINT : process.env.VUE_APP_PRODUCTION_SOCKET_ENDPOINT),
      socket: io(process.env.NODE_ENV === 'development' ? process.env.VUE_APP_SOCKET_ENDPOINT : location.protocol + '//' + location.host),
      
    }
  },
  mounted() {
    if(this.socket) {
      console.log('Connected to websocket')
    }
  },
  methods: {
    async fetchData(url, method, body, authenticate) {
      let options = {
        method,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'charset': 'utf-8',
          "Cache-Control": "no-cache, no-store, must-revalidate",
          "Pragma": "no-cache",
          "Expires": 0,
        },
        credentials: 'include'
      }

      if(body !== null) {
        options.body = body
      }

      if(authenticate) {
        if(this.getCook('accessToken') === '') {
          await this.getAccessToken().then(() => {
            const encodedCredentials = this.getCook('accessToken')
            options.headers['Authorization'] = `Bearer ${encodedCredentials}`
            // if(process.env.NODE_ENV === "production") {
            //   return fetch('https://trans.indopride.id/api' + url, options)
            // } else {
              if(process.env.NODE_ENV === 'development') {
                return fetch('http://localhost:5000/api' + url, options)
              } else {
                return fetch(location.protocol + '//' + location.host + '/api' + url, options)
              }
            // }
          })
        }
        const encodedCredentials = this.getCook('accessToken')
        options.headers['Authorization'] = `Bearer ${encodedCredentials}`;
      }
      // if(process.env.NODE_ENV === "production") {
      //   return fetch('https://trans.indopride.id/api' + url, options)
      // } else {
        // return fetch('http://localhost:5000/api' + url, options)
      if(process.env.NODE_ENV === 'development') {
        return fetch('http://localhost:5000/api' + url, options)
      } else {
        return fetch('/api' + url, options)
      }
      
      // }
    },
    getCook(cookiename) {
      // Get name followed by anything except a semicolon
      var cookiestring = RegExp(cookiename+"=[^;]+").exec(document.cookie);
      // Return everything after the equal sign, or an empty string if the cookie name not found
      return decodeURIComponent(cookiestring ? cookiestring.toString().replace(/^[^=]+./,"") : "");
    },
    async getAccessToken() {
      if(process.env.NODE_ENV !== "development") {
        await fetch(location.protocol + '//' + location.host + '/api' + '/auth/token', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'charset': 'utf-8'
          },
          credentials: 'include',
          body: JSON.stringify({token: window.localStorage.getItem('_rToken')})
        }).then(res => {
          if(res.ok) {
            return res.json()
          }
        }).then(data => {
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + (1000 * 60 * 20) // 20 mins
          now.setTime(expireTime)
          document.cookie = `accessToken=${data.accessToken};expires=${now.toUTCString()};SameSite=None; Secure`
        })
      } else {
        await fetch('http://localhost:5000/api/auth/token', {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            'charset': 'utf-8'
          },
          credentials: 'include',
          body: JSON.stringify({token: window.localStorage.getItem('_rToken')})
        }).then(res => {
          if(res.ok) {
            return res.json()
          }
        }).then(data => {
          var now = new Date();
          var time = now.getTime();
          var expireTime = time + (1000 * 60 * 20) // 20 mins
          now.setTime(expireTime)
          document.cookie = `accessToken=${data.accessToken};expires=${now.toUTCString()};SameSite=None; Secure`
        })
      }
      
    },
    getLocalStorage() {
      return localStorage.getItem('_rToken') ? true : false
    },
    fetchStatus() {
      if(this.socket) {
        this.socket.emit('broadcast:status')
        return new Promise( resolve => {
          this.socket.on("broadcast:status-sent", (result) => {
            resolve(result)
          })
        })
        // return result
      }
    }
  },
  provide() {
    return {
      apiUrl: this.apiUrl,
      fetchData: this.fetchData,
      socket: this.socket,
      fetchStatus: this.fetchStatus,
    }
  }, 
  beforeUnmount() {
    this.socket.removeAllListeners();
    this.socket.disconnect();
  }
}
</script>

<style>
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
  font-family: 'Inconsolata', monospace;
  margin: 0;
  height: 100vh;
  width: 100vw;
}
#half {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

  .half {
      display: flex;
  }
  .dashboard__left {
      width: 20%;
      background: rgb(0,95,252);
      height: 100vh
  }
</style>
