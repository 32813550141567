<template>
  <div class="modal__container" :class="customClass">
    <div class="card">
      <p>are you sure that you want to delete user {{firstName}} {{lastName}} ?</p>
      <div class="button-flex">
        <base-button @click="$emit('cancel')" custom-class="modal">No</base-button>
        <base-button custom-class="modal modal-yes" @click="deleteHandler(userId)">Yes</base-button>
      </div>
      
    </div>
  </div>
</template>

<script>
  import BaseButton from '../UI/BaseButton.vue'
  export default {
    components: {
      BaseButton
    },
    props: ['firstName', 'lastName', 'userId', 'customClass'],
    methods: {
      handleClickOutside(e) {
        if (this.$el.className === e.target.className) {
          this.$emit('cancel')
        }
      },
      deleteHandler(userId) {
        this.fetchData(`/users/delete/${userId}`, 'DELETE', null, true)
          .then(res => {
            if(!res.ok) {
              return res.json()
            } else {
              this.$emit('success')
            }
          })
          .then(data => {
            if(data !== undefined) {
              this.$emit('error-message', data.msg)
            }
          })
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside);
    },
    unmounted() {
      document.removeEventListener('click', this.handleClickOutside);
    },
    inject: ['fetchData']
  }
</script>

<style scoped>
  .modal__container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.5);
    z-index: 99999999999;
    opacity: 0;
    pointer-events: none;
  }

  .animateModal {
    opacity: 1;
    pointer-events: initial;
  }

  .revertAnimateModal{
    opacity: 0;
    pointer-events: none;
  }

  .animateModal, .revertAnimateModal {
    transition: all .2s;
  }
  
  .card {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 40%;
    background: #fff;
    padding: 40px;
    border-radius: 10px;
  }
  p {
    font-size: 1.2em;
    text-align: center;
  }
  .button-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>