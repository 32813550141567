<template>
    <header>
        <!-- <img src="/img/bell.svg" alt="Bell Notification" class="header__notification"> -->
        <base-button custom-class="refresh" @click="refreshComponent()">Refresh</base-button>
        <span><img class="header__profile" src="/img/blank-profile.png" /></span>
        <span @click="logout()" class="logout">Logout</span>
    </header>
</template>

<script>
    import BaseButton from '../UI/BaseButton.vue'
    export default {
        components: {
          BaseButton
        },
        methods: {
            logout() {
                localStorage.removeItem('_rToken')
                document.cookie = 'accessToken=; Max-Age=-9999999999;'
                this.$router.push('/')
            },
            refreshComponent() {
              this.$parent.refresh(this.$route.name)
            }
        }
    }
</script>

<style scoped>
    header {
        width: 100%;
        height: 70px;
        text-align: right;
        padding-top: 20px;
        position: sticky;
        top: 0;
        z-index: 999;
        background: linear-gradient(to right, #EFF2FB, #F5F7FB)
    }
    .logout {
        display: inline-block;
        vertical-align: top;
        padding-top: 15px;
        cursor: pointer;
        margin-right: 20px;
    }
    img {
        width: 40px;
        display: inline-block;
        margin: 0 10px
    }
    .header__profile {
        border-radius: 50%;
        margin-right: 30px

    }
    .header__notification {
        width: 30px;
        padding-bottom: 5px;
    }
</style>