<template>
  <input type="text" v-model="input" placeholder='Search user' />
</template>

<script>
  export default {
    props: ['users'],
    data() {
      return {
        input: ''
      }
    },
    watch: {
      input() {
        this.filterUser();
      }
    },
    methods: {
      filterUser() {
        const newArr =  this.users.filter((user) => {
          const fullName = `${user.firstName.toLowerCase()}${user.lastName.toLowerCase()}`
          return fullName.includes(this.input.toLowerCase().replace(/\s/g, ''));
        })
        this.$parent.setUser(newArr)
      }
    }
  }
</script>

<style scoped>
  input {
    display: block;
    width: 200px;
    margin: 20px 0;
    padding: 10px;
    background: white url("../../../public/img/search-icon.svg") no-repeat 10px center;
    background-size: 15px 15px;
    font-size: 14px;
    padding-left: 35px;
    border: none;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px 2px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
</style>