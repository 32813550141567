<template>
  <input type="radio" :id="customId" name="rating" :value="customValue" @input="handleInput" /><label class = "full" :for="customId"></label>
  <input type="radio" :id="customId + 'half'" name="rating" :value="customValue - 0.5" @input="handleInput" /><label class="half" :for="customId+'half'"></label>
</template>

<script>

  export default {
    props: ['modelValue', 'customId', 'customValue'],
    emits: ['update:modelValue'],
    methods: {
      handleInput(e) {
        this.$emit('update:modelValue', e.target.value)
      }
    },
    
  }
</script>

<style scoped>
  @import url(https://netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
input { display: none; } 
label:before { 
  margin: 5px;
  font-size: 2.5em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

label.half:before { 
  content: "\f089";
  position: absolute;
  width: 20px;
}

label { 
  color: #ddd; 
  float: right; 
}

input:checked ~ label,
label:hover,
label:hover ~ label { color: #FFD700;  }

input:checked + label:hover,
input:checked ~ label:hover,
label:hover ~ input:checked ~ label,
input:checked ~ label:hover ~ label { color: #FFED85;  } 
</style>