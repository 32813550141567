<template>
  <div>
    <div class="timetable__container" v-for="(user, i) in users" :key="i" >
        <h2>{{user.firstName}} {{user.lastName }} - {{user.associatedRole.role}}</h2>
        <!-- <timetable-table :timesheet="allTimeTable" :user-id="user.id"></timetable-table> -->
        <div class="rating__container">
          <div class="grid">
              <div class="cell__first timetable__header">
                  <div class="cell cell__header">Tanggal</div>
              </div>
              <div class="timetable__header">
                  <div class="cell cell__header">Nama Pelanggan</div>
              </div>
              <div class="timetable__header">
                  <div class="cell cell__header">Rating</div>
              </div>
              <div class="timetable__header">
                  <div class="cell cell__header">Comment</div>
              </div>
          </div>
          <div v-if="allRating !== undefined">
            <div class="grid" v-for="(rating, i) in filteredRatingArray(user.id)" :key="i">
              <rating-cell :date="convertDate(rating.date)" :customer-name="rating.customerName" :rating="rating.rating" :comment="rating.comment"></rating-cell>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>

<script>
  import RatingCell from './RatingCell.vue';
  import moment from 'moment';
  export default {
    components: {
      RatingCell
    },
    props: ['users', 'allRating', 'isAdmin'],
    methods: {
      filteredRatingArray(userId) {
        if(this.allRating === undefined) {
          return
        }
        return this.allRating.filter(function(el) {
          return el.userId === userId
        })
      },
      convertDate(date) {
        const dateMoment = moment(date)
        if(!this.isAdmin) {
          const dateComponent = dateMoment.utc().format('DD/MM/YYYY')
          return dateComponent
        }
        
        const dateComponent = dateMoment.utc().format('DD-MM-YYYY')
        const timeComponent = dateMoment.utc().format('HH:mm:ss')

        return dateComponent + " " + timeComponent
      }
    }
  }
</script>

<style>
  .rating__container {
    margin-top: 20px;
  }
  .grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
</style>