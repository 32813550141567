<template>
  <div>
    <div class="msg__container">
      <p class="success" v-if="successMessage">Berhasil: {{successMessage}}</p>
      <p class="error" v-if="errorMessage">Error: {{errorMessage}}</p>
    </div>
    <div class="carlog__container">
      <div class="grid">
          <div class="cell__first timetable__header">
              <div class="cell cell__header">Nama</div>
          </div>
            <div class="timetable__header">
              <div class="cell cell__header">Total Mobil</div>
          </div>
            <div class="timetable__header">
              <div class="cell cell__header">Total Motor</div>
          </div>
      </div>
      <div class="grid" v-for="(user, i) in users" :key="i">
        <car-log-cell :user="user" :allCarLog="carLog" :key="rerender"></car-log-cell>
      </div>
    </div>
  </div>
</template>

<script>
  import CarLogCell from './CarLogCell.vue';
  export default {
    props: ['users', 'carLog'],
    components: {
      CarLogCell
    },
    data() {
        return {
            successMessage: '',
            errorMessage: '',
            rerender: 0,
        }  
    },
    watch: {
      carLog() {
        this.rerender++
      }
    }
  }
</script>

<style scoped>
  .carlog__container {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
</style>