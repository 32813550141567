<template>
  <button :class="customClass"><slot></slot></button>
</template>

<script>
  export default {
    props: ['customClass']
  }
</script>

<style scoped>
  button {
    width: 100%;
    color: #fff;
    background: #01559B;
    border-radius: 10px;
    padding: 15px;
    font-size: 1.2em;
    border: none;
    margin-top: 15px;
    cursor: pointer;
  }
  .dashboard {
    width: auto;
    background: transparent;
    color: #000;
    font-size: 1em;
    cursor: pointer;
  }
  .on-duty {
    background: green;
  }

  .on-duty:disabled, .standby:disabled {
    background: rgb(142, 141, 141)
  }

  .off-duty {
    background: rgb(207, 1, 1);
  }

  .profile__button, .rating__button {
    width: 100px;
    margin-top: 0;
    padding: 10px;
  }

  .profile__discard {
    background-color: rgb(103, 103, 103);
    color: white;
  }

  .total__button {
    width: 100px;
    padding: 10px 15px;
    margin-top: 0;
    font-size: 1em;
    opacity: 0;
    scale: 0;
    transition: all .2s;
  }

  .animate {
    opacity: 1;
    scale: 1;
  }

  .refresh {
    width: 100px;
    margin: 0;
    padding: 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
  }

  .delete {
    background: #950303;
    margin: 10px 0;
    width: 100px;
    padding: 10px 15px;
  }


  .modal-yes {
    margin-left: 20px;
    background: #950303
  }

  /* .animate, .revertAnimate {
    
  } */

  @media only screen and (max-width: 1200px) {
    .on-duty, .off-duty {
      padding: 15px 10px;
      font-size: 1em;
    }
  }
</style>

