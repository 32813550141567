<template>
  <form @submit.prevent>
    <div v-if="err">
      <p class="error error__text" v-for="error in errorMessage" :key="error">{{error}}</p>
    </div>
    <div v-if="successMessage">
      <p class="success">{{successMessage}}</p>
    </div>
    <div class="form__control">
      <label>Role:</label>
      <select v-model="role">
        <option :value="0">Part Time</option>
        <option selected :value="1">Ekonomi</option>
        <option :value="2">Premium</option>
        <option :value="3">Bisnis</option>
        <option :value="4">Eksekutif</option>
        <option :value="5">Boss</option>
        <option :value="6">CEO</option>
      </select>
    </div>
    <div class="form__control">
      <label for="namaDepan">Nama Depan:</label>
      <base-input v-model="firstName" name="namaDepan" mode="text" placeholder-text="Silahkan masukan nama depan anda" custom-class="profile"></base-input>
    </div>
    <div class="form__control">
      <label for="namaBelakang">Nama Belakang:</label>
      <base-input v-model="lastName" name="namaBelakang" mode="text" placeholder-text="Silahkan masukan nama belakang anda" custom-class="profile"></base-input>
    </div>
    <div class="form__control">
      <label for="steamHex">Steam Hex:</label>
      <base-input v-model="steamHex" @change="resetMsg()" name="steamHex" mode="text" placeholder-text="Silahkan masukan Steam Hex karyawan" custom-class="profile" autocomplete="on"></base-input>
    </div>
    <div class="form__control">
      <label for="email">Email:</label>
      <base-input v-model="email" name="email" mode="text" placeholder-text="Silahkan masukan email anda" custom-class="profile"></base-input>
    </div>
    <div class="form__control">
      <label for="password">Password:</label>
      <base-input v-model="password" @change="resetMsg()" name="password" mode="password" placeholder-text="Silahkan masukan password baru anda" custom-class="profile" autocomplete="on"></base-input>
    </div>
    <div class="form__control">
      <label for="is-admin">Administrator:</label>
      <select v-model="isAdmin">
        <option :value="false">No</option>
        <option :value="true">Yes</option>
      </select>
    </div>
    <div class="form__control form__control--button">
      <base-button @click="resetValue()" :class="{'animate': changed, 'revertAnimate': !changed}" custom-class="profile__button profile__discard">Discard</base-button>
      <base-button @click="checkValue()" @change="resetMsg()" custom-class="profile__button">Register</base-button>
    </div>
  </form>
</template>

<script>
  export default {
    data() {
      return {
        err: false,
        successMessage: '',
        errorMessage: [],
        firstName: '',
        lastName: '',
        steamHex: '',
        email: '',
        password: '',
        role: 1,
        changed: false,
        isAdmin: false
      }
    },
    watch: {
      password(newVal) {
        newVal !== '' ? this.resetMsg() : '';
        this.checkInputValue(newVal)
      },
      firstName(newVal) {
        newVal !== '' ? this.resetMsg() : '';
        this.checkInputValue(newVal)
      },
      lastName(newVal) {
        newVal !== '' ? this.resetMsg() : '';
        this.checkInputValue(newVal)
      },
      email(newVal) {
        newVal !== '' ? this.resetMsg() : '';
        this.checkInputValue(newVal)
      },
      steamHex(newVal) {
        newVal !== '' ? this.resetMsg() : '';
        this.checkInputValue(newVal)
      },
    },
    methods: {
      resetValue() {
        this.password = '';
        this.firstName = '';
        this.lastName = '';
        this.email = '';
        this.steamHex = '';
      },
      resetMsg() {
        this.err = '';
        this.successMessage = '';
      },
      checkInputValue(newVal) {
        if(this.firstName !== '' || this.lastName !== '' || this.steamHex !== '' || this.email !== '' || this.password !== '') {
          this.changed = true;
        } else {
          this.changed = newVal !== '' ? true : false
        }
      },
      async checkValue() {
        if(this.firstName !== '' && this.lastName !== '' && this.steamHex !== '' && this.email !== '' && this.password !== '' && this.role !== '' && this.isAdmin !== '') {
          await this.fetchData('/users/create', 'POST', JSON.stringify({
            firstName: this.firstName,
            lastName: this.lastName,
            steamHex: this.steamHex,
            email: this.email,
            password: this.password,
            roleId: this.role,
            isAdmin: this.isAdmin
          }), true)
            .then(res => {
              if(res.status === 201) {
                this.successMessage = 'Armada ' + this.firstName + ' berhasil dibuat!';
                this.firstName = this.lastName = this.email = this.password = this.steamHex = '';
                this.role = 1;
                this.iSAdmin = false;
              } else {
                return res.json();
              }
            })
            .then(data => {
              if(data !== undefined) {
                this.errorMessage = [];
                this.err = true;
                this.errorMessage.push(data.msg) 
              } else {
                this.errorMessage = [];
                this.errorMessage.push('Ada gangguan dalam membuat akun baru. Mohon di coba kembali dalam beberapa saat.') 
              }
            })
        } else {
          this.errorMessage = [];
          this.err = true
          this.firstName === '' ? this.errorMessage.push('Nama Depan tidak boleh kosong!') : ''
          this.lastName === '' ? this.errorMessage.push('Nama Belakang tidak boleh kosong!') : ''
          this.steamHex === '' ? this.errorMessage.push('Steam Hex tidak boleh kosong!') : ''
          this.email === '' ? this.errorMessage.push('Email tidak boleh kosong!') : ''
          this.password === '' ? this.errorMessage.push('Password tidak boleh kosong!') : ''
        }
      }
    },
    inject: ['fetchData']
  }
</script>

<style scoped>
  form {
    margin-top: 40px
  }

  select {
    font-size: 1.2em;
    padding: 5px 15px;
    border-radius: 5px;
  }

  label {
    font-size: 1.5em;
    width: 100%;
    max-width: 300px;
    margin: 0
  }

  .form__control {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 30px;
  }

  .form__control--button {
    width: 100%;
    justify-content: space-between;
  }

  .revertAnimate {
    opacity: 0;
    scale: 0;
  }

  .animate {
    opacity: 1;
    scale: 1;
  }

  .animate, .revertAnimate {
    transition: all .2s;
  }

  .error, .success {
    color: red;
    font-weight: 700;
    text-align: left;
  }

  .success {
    color: green;
    margin-bottom: 20px;
  }

  .error__text {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
    font-size: 1.3em;
  }
</style>