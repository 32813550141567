<template>
  <base-grid>
    <div class="cell__first cell__container">
      <div class="cell cell__header">KOTA - MOTOR</div>
      <div class="cell" v-for="(i) in longestArrayComputed" :key="i">{{motorRole[i-1]}} {{motor[i - 1]}}</div>
      <div class="cell">Total Motor: {{arrayLength[0]}}</div>
    </div>
    <div class="cell__container">
      <div class="cell cell__header">KOTA - MOBIL</div>
      <div class="cell" v-for="(i) in longestArrayComputed" :key="i">{{mobilRole[i-1]}} {{mobil[i - 1]}}</div>
      <div class="cell">Total Mobil: {{arrayLength[1]}}</div>
    </div>
    <div class="cell__container">
      <div class="cell cell__header">STANDBY</div>
      <div class="cell" v-for="(i) in longestArrayComputed" :key="i">{{standbyRole[i-1]}} {{standby[i - 1]}}</div>
      <div class="cell">Total Standby: {{arrayLength[2]}}</div>
    </div>
  </base-grid>
  
</template>

<script>
  import BaseGrid from '../UI/BaseGrid.vue';
  // import SocketioService from '../../services/socketio.service.js';
  function longestArray(arr1, arr2, arr3) {
    const motor = arr1;
    const mobil = arr2;
    const standby = arr3;

    return motor.length >= mobil.length && motor.length >= standby.length ? motor.length : mobil.length >= motor.length && mobil.length >= standby.length ? mobil.length : standby.length
  }
  function calculateArrayLength(arr1, arr2, arr3) {
    return [arr1.length, arr2.length, arr3.length];
  }
  export default {
    components: {
      BaseGrid
    },
    data() {
      return {
        // motorRole: ['[EKSEKUTIF]', '[BISNIS]'],
        // motor: ['Arga Rizky', 'Anthony Winoto'],
        // mobilRole: ['[PREMIUM]', '[BISNIS]', '[BOSS]'],
        // mobil: ['Jess Huynh', 'Daniel Owen', 'Titin Ritz'],
        // standbyRole: ['[BOSS]'],
        // standby: ['Vladimir Aray Carvajal'],
        // motorRole: ['PREMIUM', 'PREMIUM', 'PREMIUM', 'PREMIUM'],
        // motor: ['Anthony Prabowo', 'Anthony Prabowo', 'Anthony Prabowo', 'Anthony Prabowo'],
        motorRole: [],
        motor: [],
        motorId: [],
        mobilRole: [],
        mobil: [],
        mobilId: [], 
        standbyRole: [],
        standby: [],
        standbyId: [],
        activeUsers: [],
        arrayLength: [],
      }
    },
    computed: {
      longestArrayComputed() {
        return longestArray(this.motor, this.mobil, this.standby)
      },
    },
    watch: {
      activeUsers() {
        // switch back to fetching
        this.$parent.toggleFetchingStatus();
        this.printStatus().then(() => {
          // after fetch, switch it back to not fetching
          this.$parent.toggleFetchingStatus();
          // this.$parent.print()
        });
      }, 
    },
    async created() {
      this.fetchStatus().then(data => {
        this.activeUsers = data;
        this.printStatus();
      })
    },
    inject: ['fetchStatus', 'socket'],
    mounted() {
      this.socket.on('broadcast:updated-status-sent', result => {
        this.activeUsers = result
      })
    },
    beforeUnmount() {
      this.socket.off('broadcast:updated-status-sent')
    },
    methods: {
      printStatus() {
        this.flush();
        this.activeUsers.forEach((user) => {
          if(user.status === 'Motor') {
            this.motorRole.push("[" + user.associatedUser.associatedRole.role.toUpperCase() + "]")
            this.motor.push(user.associatedUser.firstName + " " +  user.associatedUser.lastName);
            this.motorId.push(user.associatedUser.id);
          }
          else if(user.status === 'Mobil') {
            this.mobilRole.push("[" + user.associatedUser.associatedRole.role.toUpperCase() + "]")
            this.mobil.push(user.associatedUser.firstName + " " +  user.associatedUser.lastName);
            this.mobilId.push(user.associatedUser.id);
          } 
          else {
            this.standbyRole.push("[" + user.associatedUser.associatedRole.role.toUpperCase() + "]")
            this.standby.push(user.associatedUser.firstName + " " +  user.associatedUser.lastName);
            this.standbyId.push(user.associatedUser.id);
          }
        })
        this.arrayLength = calculateArrayLength(this.motor, this.mobil, this.standby)
        return Promise.resolve()
      },
      flush() {
        if(this.standbyRole.length !== 0 || this.mobilRole.length !== 0 || this.motorRole.length !== 0) {
          this.motorRole = []
          this.motor = []
          this.motorId = []
          this.mobilRole = []
          this.mobil = []
          this.mobilId = []
          this.standbyRole = []
          this.standby = []
          this.standbyId = []
        }
      }  
    },
  }
</script>

<style>
  .cell {
    flex: 0 0 32%;                       /* don't grow, don't shrink, width */
    height: 50px;
    background-color: rgb(181, 181, 181);
    text-align: center;
    padding: 0 10px;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px black;
    border-left: none;
    min-width: 150px;
  }
  .cell__first {
    border-left: solid 1px black;
  }
  .cell__header {
    font-size: 20px;
    background: rgb(1, 40, 102);
    color: white;
  }

  @media only screen and (max-width: 1000px) {
    .cell__container{
      width: 33.33%;
    }
  }
</style>