<template>
  <h3>Total Insentif: $IDP {{new Intl.NumberFormat("id-ID", { minimumFractionDigits: 0 }).format(totalSum)}}</h3>
</template>

<script>
  export default {
    props: ['totalSum']
  }
</script>

<style scoped>
  h3 {
    font-size: 1.5em;
    font-weight: 700;
  }
</style>