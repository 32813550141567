<template>
    <div class="container">
        <h1>Monthly Car Log</h1>
        <Datepicker class="date__picker" v-model="month" monthPicker :enableTimePicker="false"></Datepicker>
        <base-search :users="allUsers"></base-search>
        <car-log-table v-if="!fetching" :users="users" :carLog="allCarLog" :key="rerender"></car-log-table>
    </div>
</template>

<script>
  import Datepicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import CarLogTable from './CarLogTable.vue';
  import BaseSearch from '../UI/BaseSearch.vue';
  export default {
    components: {
      Datepicker, CarLogTable, BaseSearch
    },
    data() {
      return {
        month: this.getMonth(),
        firstLast: this.getFirstLastDate(),
        allCarLog: [],
        errorMessage: '',
        users: [],
        allUsers: [],
        fetching: false,
        rerender: 0,
      }
    },
    watch: {
      month(newVal) {
        this.firstLast = this.getFirstLastDate(newVal)
      },
      async firstLast() {
        await this.fetchCarLog();
      }
    },
    mounted() {
      this.fetchAllData();
    },
    methods: {
      getMonth() {
        const month = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"})).getMonth()
        const year = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"})).getFullYear()
        

        return {month, year}
      },
      getFirstLastDate(val) {
        var date 
        if(val) {
          date = new Date(val.year, val.month)
        } else {
          date = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
        }
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        

        return [firstDay, lastDay]
      },
      async fetchAllData() {
        this.fetching = true;
        await this.fetchData('/users/all', 'GET', null, true)
        .then(res => {
          return res.json()
        })
        .then(async (data) => {
          if(data.msg === undefined) {
            this.users = data.users
            this.allUsers = data.users
            this.fetchCarLog()
          } else {
            this.errorMessage = data.msg
            this.fetching = false;
          }
        })
      },
      async fetchCarLog() {
        await this.fetchData(`/carlog/all-car-log/${this.firstLast[0]}/${this.firstLast[1]}`, 'GET', null, true)
          .then(res => {
            return res.json()
          })
          .then(data => {
            if(data.allCarLog !== undefined) {
              this.allCarLog = data.allCarLog
            } else {
              this.errorMessage = data.msg
            }
            this.fetching = false;
          })
      },
      setUser(newUserArr) {
        this.users = newUserArr
        this.rerender++
      },
    },
    inject: ['fetchData']
  }
</script>

<style scoped>
  .date__picker {
    margin-top: 20px;
  }
</style>