<template>
  <div class="half">
    <div class="dashboard__left">
      <logo custom-class="dashboard__logo" div-class="dashboard__header" img-src="./img/logo-trans-white.png"></logo>
    </div>
    <div class="dashboard__right">
      <div class="overlay overlay--alt" v-if="loading">
        <div class="overlay__inner">
            <div class="overlay__content"><span class="spinner"></span></div>
        </div>
      </div>
      <div class="container">
        <h1>Rating</h1>
        <div class="separator separator2" :class="{'animate2': animate}">
          <h2>Rating berhasil di terima. Terima Kasih!</h2>
        </div>
        <div v-if="errorMsg !== ''">
          <p class="error error__text">{{errorMsg}}</p>
        </div>
        <div class="seperator" :class="{'animate1': animate}">
          <div class="form__control">
            <label for="customerName">Nama Pelanggan: </label>
            <base-input name="customerName" disabled v-model="customerName" mode="text" placeholder-text="Silahkan masukan nama depan anda" custom-class="profile"></base-input>
          </div>
          <div class="form__control">
            <label for="driver">Nama Driver:</label>
            <base-input name="driver" v-model="driver" disabled mode="text" placeholder-text="Silahkan masukan nama driver" custom-class="profile autocomplete__child"></base-input>
          </div>
          <div class="form__control">
            <label for="driver">Rate Driver: </label>
            <fieldset class="rating">
              <base-star custom-id="star5" v-model="rating" custom-value="5"></base-star>
              <base-star custom-id="star4" v-model="rating" custom-value="4"></base-star>
              <base-star custom-id="star3" v-model="rating" custom-value="3"></base-star>
              <base-star custom-id="star2" v-model="rating" custom-value="2"></base-star>
              <base-star custom-id="star1" v-model="rating" custom-value="1"></base-star>
            </fieldset>
          </div>
          <div class="form__control">
            <label for="driver">Comment:</label>
            <textarea name="driver" v-model="comment" mode="text" :placeholder="'Silahkan masukan komentar anda untuk driver ' + driver" class="comment"></textarea>
          </div>
          <div class="form__control form__control--button">
            <base-button @click="checkValue()" @change="resetMsg()" custom-class="rating__button">Submit</base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import BaseStar from '../UI/BaseStar.vue'
  import Logo from './Logo.vue';
  import BaseInput from '../UI/BaseInput.vue'
  const urlSearchParams = new URLSearchParams(window.location.search);
  export default {
    components: {
      BaseStar, Logo, BaseInput
    },
    data() {
      return {
        rating: 0,
        customerName: urlSearchParams.get('customerName'),
        steamHex: urlSearchParams.get('steamHex'),
        customerSteamHex: urlSearchParams.get('customerSteamHex'),
        driver: '',
        driverMetadata: {},
        errorMsg: '',
        animate: false,
        comment: '',
        loading: false,
      }
    },
    async created() {
      let url;
      if(process.env.NODE_ENV === 'development') {
        url = 'http://localhost:5000/api'
      } else {
        url = "/api"
      }
      fetch(url + `/users/find-hex/${this.steamHex}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'charset': 'utf-8',
        }
      })
        .then(res => {
          if(res.ok) {
            return res.json();
          }
        })
        .then(data => {
          this.driver = data.user.firstName + ' ' + data.user.lastName
          this.driverMetadata = data.user
        })
        

    },
    methods: {
      checkValue() {
        if(this.rating !== 0 && this.customerName !== '' && this.driver !== '' && !this.loading) {
          this.loading = true;
          var url;
          if(process.env.NODE_ENV === 'development') {
            url = 'http://localhost:5000/api'
          } else {
            url = "/api"
          }
          fetch(url + '/rating/post-rating', {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              'charset': 'utf-8',
            },
            body: JSON.stringify({
              id: this.driverMetadata.id,
              customerName: this.customerName,
              customerSteamHex: this.customerSteamHex,
              rating: this.rating,
              comment: this.comment
            })
          })
            .then(res => {
              if(!res.ok) {
                return res.json();
              } else {
                this.errorMsg = '';
                this.animate = true;
                this.socket.emit('broadcast:updateLeaderboard')  
                this.loading = false;
              }
            })
            .then(data => {
              if(data !== undefined) {
                this.errorMsg = data.msg
                this.loading = false;
              }
            })
        } else {
          if(this.driver === '' || this.customerName === '') {
            this.errorMsg = 'Silahkan rate driver melalui sistem di dalam kota'
          } else {
            this.errorMsg = 'Rate Driver tidak boleh kosong!'
          }
          
        }
      }
    },
    inject: ['socket'],
    unmounted() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  }
</script>

<style scoped>
  h1 {
    position: relative;
  }
  h2 {
    margin-top: 40px;
    font-size: 1.7em;
    position: absolute;
  }
  .half {
        display: flex;
        background: #EFF2FB;
        overflow: hidden;
        height: 100vh;
    }
    .dashboard__left {
        width: 20%;
        background: rgb(0,95,252);
        height: 100vh;
        position: relative;
        z-index: 0;
        min-width: 250px;
        padding-top: 40px;
    }
    .dashboard__right {
        width: 80%;
        background: linear-gradient(to right, #EFF2FB, #F5F7FB);
        overflow: hidden;
        overflow-y: auto;
        padding-top: 40px;
    }
    .container {
      padding: 0 30px;
      padding-right: 10px;
      width: 100%;
    }
    label {
      font-size: 1.7em;
      width: 100%;
      max-width: 250px;
      margin: 0
    }
    .separator {
      transform: translateX(0);
      opacity: 1
    }
    .separator2 {
      transform: translateX(150%);
      opacity: 0;
    }

    .animate1 {
      transform: translateX(-100%);
      opacity: 0
    }

    .animate2 {
      transform: translateX(0);
      opacity: 1;
    }

    .animate1, .animate2 {
      transition: all 0.5s;
    }
  
  .comment {
    width: 100%;
    height: 70px;
    border-radius: 5px;
    border: solid 1px #ccc;
    padding: 10px
  }

  .form__control {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  
  .form__control--button {
    justify-content: flex-end;
  }
  .rating {
    float: left;
  }
  .autocomplete {
    position: relative;
    width: 100%;
  }
  ul {
    margin-top: 0;
    position: absolute;
    width: 100%;
    background: #fff;
    border: solid 1px #ccc;
    border-radius: 5px;
    z-index: 9999;
  }
  li {
    padding: 5px 10px;
  }

  .error {
    color: red;
    font-weight: 700;
    text-align: left;
    width: 90%
  }

  .rating__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 2px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #fff;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
}
.overlay--alt {
  background: rgba(0,0,0,0.4)
}
</style>