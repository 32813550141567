<template>
    <div>
        <div>
            <base-button custom-class="on-duty" @click.stop="onDuty('Motor')" :disabled="currentCar === 'Motor'">KOTA - MOTOR</base-button>
            <base-button custom-class="on-duty" @click.stop="onDuty('Mobil')" :disabled="currentCar === 'Mobil'">KOTA - MOBIL</base-button>
            <base-button custom-class="standby" @click.stop="offDuty('Standby')" :disabled="currentCar === 'Standby'">STANDBY</base-button>
        </div>
        <div v-if="isOnDuty">
            <base-button custom-class="off-duty" @click.stop="offDuty('')">OFFDUTY</base-button>
        </div>
    </div>
</template>

<script>
    import BaseButton from '../UI/BaseButton.vue';
    export default {
        components: {
            BaseButton
        },
        data() {
            return {
                isOnDuty: false,
                currentCar: '',
            }
        },
        async created() {
            this.isOnDuty = await this.getStatus();
            this.toggleOnDuty(this.isOnDuty);
        },
        methods: {
          async getStatus() {
            return await this.fetchData('/status/check-status', 'GET', null, true)
                .then(res => {
                    if(res.ok) return res.json()
                })
                .then(data => {
                    this.currentCar = data.carType
                    return data.status === 'on-duty' ? true : false
                })
          },
          async onDuty(carType) {
            if(this.isOnDuty) {
              if(carType !== this.currentCar) {
                const addTimesheet = this.currentCar === 'Standby' ? true : false
                this.currentCar = carType
                await this.fetchData('/status/change-status', 'PUT', JSON.stringify({status: carType, addTimesheet}), true)
                  .then( res => {
                    if(!res.ok) {
                      return res.json();
                    } else {
                      this.socket.emit('broadcast:updateStatus');
                      this.$parent.toggleShouldUpdate();
                      this.resetMessage();
                      return
                    }
                  })
                  .then(data => {
                    if(data !== undefined) {
                      this.setErrorMessage(data.msg)
                      throw new Error(data.msg)
                    }
                  })
                await this.fetchData('/carlog/add-car-log', 'POST', JSON.stringify({carType}), true)
                  .then(res => {
                    if(!res.ok) {
                      return res.json()
                    }
                  })
                  .then(data => {
                    if(data === undefined) {
                      this.setErrorMessage(data.msg)
                      throw new Error(data.msg)
                    }
                  })
              }
            } else {
              if(carType !== this.currentCar) {
                this.currentCar = carType
                await this.fetchData('/status/onduty', 'POST', JSON.stringify({status: carType}), true)
                  .then(res => {
                    if(res.ok) {
                      return res.json();
                    } else {
                      console.log('Terdapat problem dalam meng-update status sinyal')
                    }
                  })
                  .then(async data => {
                    if(data.signal !== undefined) {
                      this.isOnDuty = true;
                      this.toggleOnDuty(true);
                      this.socket.emit('broadcast:updateStatus');
                      this.$parent.toggleShouldUpdate();
                      this.resetMessage();
                    } else {
                      this.$parent.setErrorMessage(data.msg)
                    }
                  })
                await this.fetchData('/carlog/add-car-log', 'POST', JSON.stringify({carType}), true)
                  .then(res => {
                    if(!res.ok) {
                      return res.json()
                    }
                    return
                  })
                  .then(data => {
                    if(data !== undefined) {
                      this.$emit('error', data.msg)
                      throw new Error(data.msg)
                    }
                  })
              }
            }
          },
          async offDuty(status) {
            if(status !== this.currentCar) {
              this.currentCar = status
              if(status === 'Standby' && this.isOnDuty) {
                await this.fetchData('/status/change-status', 'PUT', JSON.stringify({status: status}), true)
                  .then( res => {
                    if(res.status !== 204) {
                      return res.json();
                    } else {
                      this.socket.emit('broadcast:updateStatus');
                      this.resetMessage();
                      return
                    }
                  })
                  .then(data => {
                    if(data !== undefined) {
                      this.setErrorMessage(data.msg)
                      throw new Error(data.msg)
                    }
                  })
              }
              await this.fetchData(`/status/offduty`, 'DELETE', JSON.stringify({
                status
              }), true)
                  .then(async (res) => {
                      if(res.ok){
                          this.isOnDuty =  await this.getStatus();
                          this.toggleOnDuty(false);
                          this.socket.emit('broadcast:updateStatus');
                          this.socket.emit('broadcast:updateLeaderboard');
                          this.$parent.toggleShouldUpdate();
                          
                      }
                      return res.json();
                  })
                  .then(data => {
                    if(data.success === undefined) {
                      this.$parent.setErrorMessage(data.msg)
                      throw new Error(data.msg)
                    } else {
                      this.$parent.setSuccessMessage(data.success)
                      this.$parent.fetchTimesheet();
                    }
                  })
            }
          },
          resetMessage() {
            this.$parent.setErrorMessage('')
            this.$parent.setSuccessMessage('')
          }
        },
        beforeUnmount() {
          if(this.isOnDuty) {
            this.$parent.toggleShouldUpdate()
          }
        },
        inject: ['fetchData', 'toggleOnDuty', 'socket']
    }
</script>

<style>
    @media only screen and (max-width: 1200px) {
      .on-duty, .off-duty {
        padding: 15px 10px;
        font-size: 1em;
      }
    }
    @media only screen and (max-width: 1000px) {
      .button {
        width: 100%
      }
    }
</style>

