<template>
    <div class="cell__first cell__container">
        <div class="cell cell__header">ARMADA DI KOTA</div>
        <!-- <div class="cell" v-for="(i) in longestArrayComputed" :key="i">{{motorRole[i-1]}} {{motor[i - 1]}}</div> -->
        <div class="overflow__container" v-if="kota !== undefined">
            <div class="cell" v-for="(userInKota, i) in kota" :key="i">[{{userInKota.associatedRole.role.toUpperCase()}}] {{userInKota.firstName}} {{userInKota.lastName}}</div>
            <!-- <div class="cell" v-for="i in 20" :key="i">test {{i}}</div> -->
        </div>
        <div class="total__armada">Total Armada:   {{kota.length === undefined ? 0 : kota.length}}</div>
    </div>
</template>

<script>
    // import BaseGrid from '../UI/BaseGrid.vue'
    export default {
        props: ['kota']
    }
</script>

<style scoped>
    .cell, .cell__container, .grid__container {
        border: none
    }
    .cell__first {
        height: 100%;
        border-radius: 10px;
    }
    .cell__header {
        height: 5%;
    }
    .overflow__container {
        height: 89%;
        overflow: hidden;
        overflow-y: auto;
    }
    .total__armada {
        height: 5%;
        border-top: solid 1px black;
        display: flex;
        align-items: center;
        padding-left: 20px;
        font-size: 1.2em;
    }
</style>