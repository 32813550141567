<template>
  <div>
    <div class="rating__flex">
      <h1>All Rating</h1>
      <base-search :users="allUsers"></base-search>
    </div>
    <div class="table__container" v-if="fetchUser && fetchRating">
      <div class="grid">
          <div class="cell__first timetable__header">
              <div class="cell cell__header">Nama Driver</div>
          </div>
            <div class="timetable__header">
              <div class="cell cell__header">Rating</div>
          </div>
      </div>
      <div class="grid" v-for="(user, i) in users" :key="i">
        <all-rating-table :driver-name="user.firstName + ' ' + user.lastName" :all-rating="allRating" :user-id="user.id" :key="rerender"></all-rating-table>
      </div>
    </div>
  </div>
</template>

<script>
  import AllRatingTable from './AllRatingTable.vue';
  import BaseSearch from '../UI/BaseSearch.vue';
  export default {
    components: {
      AllRatingTable, BaseSearch
    },
    data() {
      return {
        allRating: [],
        users: [],
        allUsers: [],
        errMsg: '',
        fetchUser: false,
        fetchRating: false,
        rerender: 0,
      }
    },
    async created() {
      await this.fetchData('/users/all', 'GET', null, true)
        .then(res => {
          return res.json()
        })
        .then(data => {
          if(data.msg === undefined) {
            this.users = data.users
            this.allUsers = data.users
            this.fetchUser = true
          } else {
            this.errMsg = data.msg
          }
        })
    },
    async mounted() {
      await this.fetchData('/rating/all-rating', 'GET', null, true)
        .then(res => {
          return res.json();
        })
        .then(data => {
          if(data.msg === undefined) {
            this.allRating = data.allRating
            this.fetchRating = true
          } else {
            this.errMsg = data.msg;
          }
        })
    },
    methods: {
      setUser(newUserArr) {
        this.users = newUserArr
        this.rerender++
      },
    },
    inject: ['fetchData']
  }
</script>

<style scoped>
  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .cell__left {
      border-left: solid 1px black
  }

  .table__container {
    margin-top: 40px;
  }

</style>