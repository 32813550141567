<template>
  <div>
    <h1>Profile</h1>
    <div class="profile__container">
      <profile-form></profile-form>
    </div>
  </div>
</template>

<script>
  import ProfileForm from './ProfileForm.vue'
  export default {
    components: {
      ProfileForm
    }
  }
</script>

<style scoped>
  .profile__container {
    margin-top: 40px
  }
</style>