<template>
    <div v-if="!loading">
        <div class="timetable__flex">
            <h1>Timetable</h1>
            <base-search v-if="isAdmin && activeComponent !== 'TimeTableSelf'" :users="allUsers"></base-search>
        </div>
        <time-table-calendar :is-admin="isAdmin"></time-table-calendar>
        <!-- Loop through all user -->
        <div v-if="!isAdmin">
            <!-- <time-table-self  :users="users" :allTimeTable="allTimeTable"></time-table-self>
            <time-table-rating v-if="activeComponent==='TimeTableInsentif'" :users="usersArray" :is-admin="isAdmin"></time-table-rating> -->
            <component :is="loadActiveComponent" :users="users" :allTimeTable="allTimeTable" :all-rating="allRatings" :is-admin="isAdmin"></component>
        </div>
        <div v-else>
            <keep-alive>
              <component :is="loadActiveComponent" :users="users" :allTimeTable="allTimeTable" :this-sunday="insentifDate" :all-rating="allRatings" :is-admin="isAdmin" :this-monday="insentifMonday"></component>
            </keep-alive>
        </div>
    </div>
</template>

<script>
    import TimeTableCalendar from './TimeTableCalendar.vue';
    import jwt_decode from 'jwt-decode'
    import TimeTableSelf from './TimeTableSelf.vue';
    import TimeTableAll from './TimeTableAll.vue';
    import TimeTableInsentif from './TimeTableInsentif.vue';
    import BaseSearch from '../UI/BaseSearch.vue';
    import TimeTableRating from './TimeTableRating.vue';
    export default {
        components: {
            TimeTableCalendar,
            TimeTableSelf,
            TimeTableAll,
            TimeTableInsentif,
            BaseSearch,
            TimeTableRating
        },
        data() {
            return {
                allTimeTable: [],
                users: [],
                allUsers: [],
                allRatings: [],
                errorMessage: '',
                input: '',
                isAdmin: jwt_decode(localStorage.getItem('_rToken')).isAdmin,
                loading: true,
                userId: '',
                loadActiveComponent: '',
                activeComponent: 'TimeTableSelf',
                insentifDate: '',
                insentifMonday: '',
            }
        },
        watch: {
            // async $route(to) {
            //     // react to route changes...
            //     if(to.name === 'time-table') {
            //         await this.fetchTimesheet();
            //     }
            // },
            input() {
                this.filteredUser();
            },
        },
        async created() {
            const decoded = jwt_decode(localStorage.getItem('_rToken'))
            this.userId = decoded.id
            this.isAdmin = decoded.isAdmin
            this.fetchUser().then(() => {this.fetchTimesheet()});
        },
        methods: {
            setActiveComponent(name) {
              this.activeComponent = name;
              this.fetchUser()
            },
            setInsentifDate(date) {
                this.insentifDate = date
            },
            setLastMonday(date) {
              this.insentifMonday = date
            },
            async fetchUser() {
              if(this.activeComponent === 'TimeTableAll' || this.activeComponent === 'TimeTableInsentif' || this.activeComponent === 'TimeTableRating' && this.isAdmin) {
                await this.fetchData('/users/all', 'GET', null, true)
                    .then(res => {
                        return res.json()
                    })
                    .then(data => {
                        // check if the server return array instead of error message
                        if(data.msg === undefined) {
                            this.users = data.users
                            this.allUsers = data.users
                            this.loadActiveComponent = this.activeComponent
                        } else {
                            this.errorMessage = data.msg
                        }
                        this.loading = false;
                    })
            } else {
                await this.fetchData(`/users/find/${this.userId}`, 'GET', null, true)
                    .then(res => {
                        return res.json()
                    })
                    .then(data => {
                        // check if the server return array instead of error message
                        if(data.msg === undefined) {
                            if(this.activeComponent === 'TimeTableRating') {
                              const arr = []
                              arr.push(data.user)
                              this.users = arr;
                            } else {
                              this.users = data.user
                            }
                            this.allUsers = data.user
                            this.loadActiveComponent = this.activeComponent
                        } else {
                            this.errorMessage = data.msg
                        }
                        this.loading = false;
                    })
                  
            }
            },
            async fetchTimesheet() {
                if(this.isAdmin) {
                    await this.fetchData('/timesheet/all-timesheet', 'GET', null, true)
                        .then(res => {
                            if(res.ok) {
                                return res.json();
                            }
                        })
                        .then(data => {
                            console.log(data)
                            this.allTimeTable = data.allUsersTimesheet
                            console.log(this.allTimeTable)
                        })
                } else {
                    await this.fetchData(`/timesheet/find/${this.userId}`, 'GET', null, true)
                        .then(res => {
                            if(res.ok) {
                                return res.json();
                            }
                        })
                        .then(data => {
                          console.log(data)
                            this.allTimeTable = data.allUsersTimesheet

                        })
                }
            },
            setLoading(load) {
                this.loading = load;
            },
            updateTable(arr) {
                if(this.activeComponent === 'TimeTableRating') {
                  this.allRatings = arr
                } else {
                  this.allTimeTable = arr
                }
                
            },
            setUser(newUserArr) {
              this.users = newUserArr
            },
            readjustDate(date) {
              const currentDate = new Date(date)
              return currentDate
            }
        },
        inject: ['fetchData'],
        provide() {
          return {
            readjustDate: this.readjustDate
          }
        }
    }
</script>

<style scoped>
    .timetable__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    h1 {
      margin: 15px 0;
    }
</style>