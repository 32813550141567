<template>
  <div v-if="err">
      <p class="error error__text" v-for="error in errorMessage" :key="error">{{error}}</p>
    </div>
  <div v-if="successMessage">
    <p class="success">{{successMessage}}</p>
  </div>
  <delete-modal :custom-class="{animateModal: showModal, revertAnimateModal: !showModal}" :first-name="firstName" :last-name="lastName" :user-id="userId" @cancel="toggleShowModal()" @error-message="handleError" @success="successHandler()"></delete-modal>
  <div class="change__user__container">
    <div class="grid">
      <div class="cell__first timetable__header">
          <div class="cell cell__header">Nama</div>
      </div>
      <div class="timetable__header">
        <div class="cell cell__header">Roles</div>
      </div>
      <div class="timetable__header">
        <div class="cell cell__header">Administrator</div>
      </div>
      <div class="timetable__header">
        <div class="cell cell__header cell--delete"></div>
      </div>
    </div>
    <div class="grid" v-for="(user, i) in users" :key="i">
      <div class="cell cell__left">{{user.firstName}} {{user.lastName}}</div>
      <div class="cell cell--alt">
        <select @change="changeHandler($event, user.id, user.isAdmin)">
          <option :selected="user.roleId === 0" :value="0">Part Time</option>
          <option :selected="user.roleId === 1" :value="1">Ekonomi</option>
          <option :selected="user.roleId === 2" :value="2">Premium</option>
          <option :selected="user.roleId === 3" :value="3">Bisnis</option>
          <option :selected="user.roleId === 4" :value="4">Eksekutif</option>
          <option :selected="user.roleId === 5" :value="5">Boss</option>
          <option :selected="user.roleId === 6" :value="6">CEO</option>
        </select>
      </div>
      <div class="cell cell--alt">
        <select @change="changeAdminHandler($event, user.id, user.roleId)">
          <option :selected="user.isAdmin === false" :value="false">No</option>
          <option :selected="user.isAdmin === true" :value="true">Yes</option>
        </select>
      </div>
      <div class="cell cell--delete">
        <base-button custom-class="delete" @click="toggleShowModal(user.id, user.firstName, user.lastName)">Delete</base-button>
      </div>
    </div>
    
    <div class="form__control form__control--button">
      <base-button @click="resetValue()" :class="{'animate': changes.length !== 0, 'revertAnimate': changes.length === 0}" custom-class="profile__button profile__discard">Discard</base-button>
      <base-button @click="checkValue()" :class="{'animate': changes.length !== 0, 'revertAnimate': changes.length === 0}" custom-class="profile__button">Apply</base-button>
    </div>
  </div>
  
</template>

<script>
  import DeleteModal from './DeleteModal.vue'
  export default {
    components: {
      DeleteModal
    },
    data() {
      return {
        err: false,
        users: [],
        successMessage: '',
        errorMessage: [],
        changes: [],
        showModal: false,
        userId: '',
        firstName: '',
        lastName: '',
        rerender: 0
      }
    },
    async mounted() {
      await this.fetchUserData()
    },
    methods: {
      changeHandler(e, userId, isAdmin) {
        const changeObj = this.checkArrayExist(this.changes, userId)
        if(changeObj) {
          changeObj['roleId'] = parseInt(e.target.value)
        } else {
          this.changes.push({userId, roleId: parseInt(e.target.value), isAdmin})
        }
      },
      changeAdminHandler(e, userId, roleId) {
        const changeObj = this.checkArrayExist(this.changes, userId)
        if(changeObj) {
          changeObj['isAdmin'] = e.target.value === "true" ? true : false
        } else {
          this.changes.push({userId, roleId, isAdmin: e.target.value === "true" ? true: false})
        }
      },
      async checkValue() {
        await this.fetchData('/users/change-roles', 'PUT', JSON.stringify({changes: this.changes}), true)
          .then(res => {
            if(res.status === 204) {
              this.$parent.rerender()
              return
            } else {
              this.err = true;
              this.errorMessage.push('Gagal dalam mengupdate role. Mohon di coba kembali dalam beberapa saat');
            }
          })
      },
      checkArrayExist(arr, userId) {
        const checkUsername = element => element.userId === userId
        return arr.find(checkUsername) 
      },
      resetValue() {
        this.$parent.rerender();
      },
      async fetchUserData() {
        await this.fetchData('/users/all', 'GET', null, true)
          .then(res => {
            return res.json();
          })
          .then(data => {
            if(data.msg === undefined) {
              this.users = data.users
              // this.successMessage = 'Berhasil dalam mengupdate role';
              // setTimeout(() => {
              //   this.successMessage = ''
              // }, 5000)
            } else {
              this.errorMessage = [];
              this.errorMessage.push(data.msg)
              throw new Error(data.msg)
            }
          })
      },
      deleteHandler(userId, firstName, lastName) {
        console.log(userId)
        console.log(firstName)
        console.log(lastName)
      },
      toggleShowModal(userId, firstName, lastName) {
        this.userId = userId;
        this.firstName = firstName;
        this.lastName = lastName;
        this.showModal = !this.showModal;
      },
      handleError(msg) {
        this.toggleShowModal();
        this.err = true;
        this.errorMessage.push(msg);
      },
      successHandler() {
        this.toggleShowModal();
        this.fetchUserData();
      },  
    },
    inject: ['fetchData']
  }
</script>

<style scoped>
  .change__user__container {
    margin-top: 20px;
  }
  .cell--alt {
    padding: 0
  }
  .grid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 150px;
      max-width: 100%;
  }
  .cell__left {
      border-left: solid 1px black
  }
  .cell--delete {
    min-width: 100px
  }
  p {
    text-align: left;
  }
  select {
    font-size: 1.1em;
    text-align: center;
    width: 100%;
    height: 100%;
    border: none
  }
  .form__control {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 30px;
    margin-top: 20px;
  }

  .form__control--button {
    width: 100%;
    justify-content: space-between;
  }

  .revertAnimate {
    opacity: 0;
    scale: 0;
  }

  .animate {
    opacity: 1;
    scale: 1;
  }

  .animate, .revertAnimate {
    transition: all .2s;
  }

  .error, .success {
    color: red;
    font-weight: 700;
    text-align: left;
  }

  .success {
    color: green;
    margin-bottom: 20px;
  }

  .error__text {
    margin-bottom: 20px;
  }
</style>