<template>
    <h2>{{ title }}</h2>
    <span :class="customClass"><img :src="imageSrc"></span>
    <p class="total__number total__number__additional" v-if="additionalText && Math.trunc(totalNumber) === 0">{{ parseInt((totalNumber%1) * 60) }} {{additionalText}}</p>
    <p class="total__number total__number__additional" v-else-if="additionalText">{{ Math.trunc(totalNumber) }} {{description}} {{ parseInt((totalNumber%1) * 60) }} {{additionalText}}</p>
    <p class="total__number total__number__additional" v-else-if="isGaji">$IDP {{new Intl.NumberFormat("id-ID", { minimumFractionDigits: 0 }).format((totalNumber * multiplier) + (countedOvertime * 0.5 * multiplier) + bonus)}}</p>
    <p class="total__number" v-else>{{ totalNumber }}</p>
    <hr>
    <p class="description" v-if="additionalText && Math.trunc(totalNumber) === 0">{{ parseInt((totalNumber%1) * 60) }} {{additionalText}}</p>
    <p class="description" v-else-if="additionalText">{{ Math.trunc(totalNumber) }} {{description}} {{ parseInt((totalNumber%1) * 60) }} {{additionalText}}</p>
    <p class="description" v-else-if="isGaji">$IDP {{new Intl.NumberFormat("id-ID", { minimumFractionDigits: 0 }).format((totalNumber * multiplier) + (countedOvertime * 0.5 * multiplier) + bonus)}}</p>
    <p class="description" v-else>{{ totalNumber }} {{ description }}</p>
    
</template>

<script>
    export default {
        props: ['title', 'imageSrc', 'totalNumber', 'description', 'customClass', 'additionalText', 'isGaji', 'multiplier', 'countedOvertime', 'bonus'],
        methods: {
          
        }
    }
</script>

<style scoped>
    h2 {
        font-size: 25px;
        font-weight: 700;
        margin-top: 20px;
    }
    img {
        width: 50px;
    }
    span {
        border: solid 2px #FAC441;
        width: 65px;
        height: 65px;
        margin-top: 10px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }
    hr {
        width: 80%;
        border-top: solid 1px #e4e4e4;
    }

    .total__number {
        font-size: 30px;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 700;
    }

    .total__number__additional {
      font-size: 20px;
      margin-top: 20px;
    }

    .description {
        font-size: 20px;
        text-align: center;
    }

    .jam {
        border: solid 2px #70A25D;
    }

    .jam__total {
        border: solid 2px #94618E;
    }

    .break {
        border: solid 2px #71ACC3;
    }

    .gaji {
        border: solid 2px #E48188;
    }

    .gaji+.total__number {
      font-size: 20px;
      margin-top: 20px;
    }
</style>