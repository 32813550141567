<template>
  <div class="cell cell__left">{{date}}</div>
  <div class="cell">{{customerName}}</div>
  <div class="cell">{{rating}}</div>
  <div class="cell">{{comment}}</div>
</template>

<script>
  export default {
    props: ['date', 'customerName', 'rating', 'comment']
  }
</script>

<style>
  .cell__left {
    border-left: solid 1px #000
  }
  .cell {
    min-height: 50px;
    height: auto;
    overflow-wrap: anywhere;
  }
</style>