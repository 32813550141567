<template>
  <dashboard-container></dashboard-container>
  <!-- <base-button @click="logout()" custom-class="dashboard">Logout</base-button> -->
</template>

<script>
  import DashboardContainer from '../Containers/DashboardContainer.vue';
  export default {
    inject: ['fetchData'],
    components: {
      DashboardContainer
    },
    methods: {
      
    },
    mounted() {
      this.fetchData('/', 'GET', null, true, null)
    },
  }
</script>