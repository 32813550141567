<template>
    <main>
      <div class="main__left">
        <h1>Dashboard <span class="light">{{ timestamp }}</span></h1>
        <div class="error" v-if="errorMsg !== ''">
          Error: {{errorMsg}}
        </div>
        <div class="success" v-if="successMsg !== ''">
          Success: {{successMsg}}
        </div>
        <div class="card__flex">
            <div class="card__container" v-for="(card, i) in cards" :key="i">
                <base-card :custom-class="card.customClass" :title="card.title" :image-src="card.imgSrc" :total-number="card.totalNumber" :description="card.description" :additional-text="card.additionalText" :is-gaji="card.isGaji" :multiplier="card.multiplier" :counted-overtime="card.countedOvertime" :bonus="card.bonus"></base-card>
            </div>
        </div>
        <div class="body__flex">
          <dashboard-table class="table" ref="table"></dashboard-table>
          <dashboard-buttons :key="buttonKey" @error="setErrorMessage" class="button"></dashboard-buttons>
        </div>
        <div class="user__rating">
          Rating anda: {{totalRating}}<img src="/img/yellow-star.svg" alt="yellow star image" />
        </div>
        <div class="leaderboard">
          <h2>Leaderboard Bulan {{month}}</h2>
          <div class="leaderboard__flex">
            <div>
              <div class="grid">
                <div class="cell__first timetable__header">
                    <div class="cell cell__header">Ranking</div>
                </div>
                <div class="timetable__header">
                    <div class="cell cell__header">Nama</div>
                </div>
                <div class="timetable__header">
                    <div class="cell cell__header">Total Score</div>
                </div>
              </div>
              <div class="grid__item" v-for="(leaderboard, i) in leaderboards" :key="i">
                <div class="cell cell__left">{{i + 1}}</div>
                <div class="cell">{{leaderboard.name}}</div>
                <div class="cell">{{leaderboard.totalPoint}}</div>
              </div>
            </div>
            <div class="leaderboard__right">
              Total Score anda: {{selfRating}}
            </div>
          </div>
          </div>
          
      </div>
      <div class="main__right">
        <main-kota-table :kota="kota"></main-kota-table>
      </div>  
    </main>
</template>
<script>
    import BaseCard from '../UI/BaseCard.vue';
    import DashboardTable from './DashboardTable.vue';
    import DashboardButtons from './DashboardButtons.vue';
    import MainKotaTable from './MainKotaTable.vue';
    import jwt_decode from 'jwt-decode';
    import moment from 'moment'
    export default {
        components: {
            BaseCard, DashboardTable, DashboardButtons, MainKotaTable
        },
        data() {
            return {
                timestamp: '',
                cards: [
                    {customClass: '', title: 'Drivers', imgSrc: '/img/id-card.svg', totalNumber: 0, description: 'driver'},
                    {customClass: 'jam', title: 'Jam', imgSrc: '/img/working-hours-icon.svg', totalNumber: 0, description: 'Jam', additionalText: 'Menit'},
                    {customClass: 'jam__total', title: 'Jam Total', imgSrc: '/img/clock-icon.svg', totalNumber: 0, description: 'Jam', additionalText: 'Menit'},
                    {customClass: 'gaji', title: 'Est. Gaji', imgSrc: '/img/payday-icon.svg', totalNumber: 0, description: '', isGaji: true, multiplier: 0, countedOvertime: 0, bonus: 0},
                ],
                output: null,
                fetchingStatus: false,
                shouldUpdate: [],
                dailyHour: 0,
                gajiCounted: false,
                errorMsg: '',
                successMsg: '',
                overtimeExceed: false,
                kota: [],
                totalRating: 0,
                leaderboards: [],
                selfRating: 0,
                month: '',
                buttonKey: 0,
            }
        },
        watch: {
          async fetchingStatus(newVal) {
            if(!newVal && this.shouldUpdate.length !== 0) {
              for(let i = 0; i < this.shouldUpdate.length; i++) {
                await this.print().then(() => this.shouldUpdate.pop(0)).catch(() => {
                  this.errorMsg = 'Terjadi problem saat mengupdate status sinyal di discord'
                })
              }
            }
          },
          $route (to){
            if(to.name === 'dashboard') {
              console.log('refreshing')
              this.buttonKey++;
            }
          },
          month(newVal) {
            switch(newVal) {
              case 0:
                this.month =  'January'
                break;
              case 1:
                this.month =  'February'
                break;
              case 2:
                this.month =  'Maret'
                break;
              case 3:
                this.month =  'April'
                break;
              case 4:
                this.month =  'May'
                break;
              case 5:
                this.month =  'Juni'
                break;
              case 6:
                this.month =  'Juli'
                break;
              case 7:
                this.month =  'Agustus'
                break;
              case 8:
                this.month =  'September'
                break;
              case 9:
                this.month =  'October'
                break;
              case 10:
                this.month =  'November'
                break;
              case 11:
                this.month =  'December'
                break;
            }
          }
        },
        async created() {
            setInterval(() => {
                this.getNow()
            }, 1000);
            this.cards[0].totalNumber = await this.fetchStatus().then(data => {
              return data.length === 0 ? 0 : data.length
            })
            this.fetchIngameUser()
            this.fetchUserRating()
            this.month = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"})).getMonth();
            this.socket.emit('broadcast:updateLeaderboard')  
        },
        async mounted() {
          this.socket.on('broadcast:updated-status-sent', result => {
            this.cards[0].totalNumber = result.length === 0 ? 0 : result.length
            this.cards[0].description = result.length > 1 ? 'drivers' : 'driver'
          })
          this.socket.on('broadcast:update-kota', result => {
            this.kota = result
            this.socket.emit('broadcast:updateStatus');
          })
          this.socket.on('broadcast:updateLeaderboard', result => {
            var userId;
            try {
              userId = jwt_decode(localStorage.getItem('_rToken')).id
            } catch(err) {
              this.errorMsg = 'Terjadi problem saat ingin meng-update leaderboard. Silahkan refresh halaman ini'
            }
            this.selfRating = result[userId] !== undefined ? parseFloat((result[userId].rating / 5) + ((result[userId].totalTime / 3) * 10)).toFixed(2) : 0
            
            const leaderboard = Object.keys(result).map(function(k){
              return {
                name: result[k].name,
                totalPoint: k === '3ca3daed-07b8-4bba-bb59-a785cd047ed8' ? parseFloat((result[k].rating / 10) + ((result[k].totalTime / 6) * 10)).toFixed(2) : parseFloat((result[k].rating / 5) + ((result[k].totalTime / 3) * 10)).toFixed(2)
              }
            });
            // console.log(leaderboard)
            leaderboard.sort((x,y) => y.totalPoint-x.totalPoint);
            this.leaderboards = leaderboard.splice(0,4)
          })
          this.fetchTimesheet();
        },
        methods: {
          formatDate(date) {
            return moment(date).utc().format('DD/MM/YYYY')
          },
          getNow() {
            const date = new Date((new Date).toLocaleString("en-US", {
                timeZone: "Asia/Jakarta"
            }));

            let day = '';
            let hours = '';
            let minutes = '';
            let seconds = '';
            if(date.getDay() == 1) {
                day = 'Senin'
            } else if (date.getDay() == 2) {
                day = 'Selasa'
            } else if (date.getDay() == 3) {
                day = 'Rabu'
            } else if (date.getDay() == 4) {
                day = 'Kamis'
            } else if (date.getDay() == 5) {
                day = 'Jumat'
            } else if (date.getDay() == 6) {
                day = 'Sabtu'
            } else if (date.getDay() == 0) {
                day = 'Minggu'
            } 
            hours = date.getHours().toString().length === 1 ? '0' + date.getHours() : date.getHours().toString();
            minutes = date.getMinutes().toString().length === 1 ? '0' + date.getMinutes() : date.getMinutes().toString();
            seconds = date.getSeconds().toString().length === 1 ? '0' + date.getSeconds() : date.getSeconds().toString();
            const fulldate = day + ', ' + date.getDate() + '/' + date.getMonth() + "/" + date.getFullYear() + ", " + hours + ':' + minutes + ':' + seconds
            this.timestamp = fulldate
          }, 
          async print() {
            console.log('printing...')
            const el = this.$refs.table.$el;
            // add option type to get the image version
            // if not provided the promise will return 
            // the canvas.
            this.$html2canvas(el, {
              type: 'dataURL',
            }).then((canvas) => {
              this.fetchData('/discord/post-image', 'POST', JSON.stringify({imgBase64: canvas}), false )
                .then(res => {
                  return new Promise((resolve, reject) => {
                    if(!res.ok) {
                      reject();
                    } else {
                      this.fetchingStatus = false;
                      resolve();
                    }
                  })
                    
                })
            })
          } ,
          toggleFetchingStatus() {
            this.fetchingStatus = !this.fetchingStatus
          },
          toggleShouldUpdate() {
            this.shouldUpdate.push(true)
          },
          async fetchTimesheet() {
            const token = localStorage.getItem('_rToken')
            if(token) {
              const decoded = jwt_decode(token)
              await this.fetchData(`/timesheet/oneday/${decoded.id}`, 'GET', null, true)
                .then(res => {
                  if(res.ok) {
                    return res.json();
                  } else {
                    return
                  }
                })
                .then(data => {
                  if(this.cards[1].totalNumber !== 0) this.cards[1].totalNumber = 0;
                  for(let i = 0; i < data.timesheets.length; i++) {
                    if(data.timesheets[i].hours === null && data.timesheets.length === 0) {
                      this.cards[1].totalNumber = 0
                    } else {
                      this.cards[1].totalNumber += data.timesheets[i].hours
                    }
                  }
                  
                })
              await this.fetchData(`/timesheet/oneweek/${decoded.id}`, 'GET', null, true)
                .then(res => {
                  if(res.ok) {
                    return res.json();
                  } else {
                    return
                  }
                })
                .then(data => {
                  if(data.timesheets.length !== 0) {
                    // defining multiplier
                    switch(data.timesheets[0].associatedUser.associatedRole.role) {
                      case 'Part Time':
                        this.cards[3].multiplier = 0;
                        break;
                      case 'Ekonomi':
                        this.cards[3].multiplier = 20000;
                        break;
                      case 'Premium':
                        this.cards[3].multiplier = 30000;
                        break;
                      case 'Bisnis':
                        this.cards[3].multiplier = 35000;
                        break;
                      case 'Eksekutif':
                        this.cards[3].multiplier = 35000;
                        break;
                    }
                    

                    if(decoded.id === '660abe9e-692d-45d4-b1f0-78f146f23b52' || decoded.id === '2e077815-2d61-4aa0-baa4-306c2e0a26bf' || decoded.id === 'e8738dbf-2c72-41f0-a389-36fe7df2c38f' ||
                     decoded.id === 'ba58f3dd-f9f4-4621-847c-a648d8eeac02' || decoded.id === '75d3a72a-2540-4223-b27e-9b193b7e609d' ||
                    decoded.id === '07ad27f8-0294-4b9d-b7f9-d4257b0ffcaf' || decoded.id === '00396799-b87c-47ff-b67b-4287033bdfc1') {
                      this.cards[3].bonus = 200000
                    } else if(decoded.id === '96e773e5-6c30-408e-9808-7d7df3d776f2' || decoded.id === '28e597cf-ebfd-41ab-95c2-c5477b11ba1b' || decoded.id === 'fc799143-55e8-4725-9113-788293dcb026' || decoded.id === '8a512ce2-c53f-45a3-bcfd-08669cfbe54c' || decoded.id === '8dcbd56c-d6ef-472c-8b8c-407f0398d2e0' ||
                    decoded.id === '18855034-7024-4b48-8890-68413a0801ac' ||
                    decoded.id === '9d02446a-7c9c-4fc8-9553-2f8e71e38802' ||                decoded.id === '5cf3a9b1-43a7-4229-86dd-95ebad0be46f' || decoded.id === '58274ef2-b4f0-4929-91d7-a4199b911c83' || decoded.id === '9eec3bf4-bd09-4896-bc3c-9a9c7af90e6e' || decoded.id === '0b1f18a4-750a-4fe2-a90c-5e3c928b023d' || decoded.id === '87f34017-628c-4aef-a141-e94ea185747e' || decoded.id === '62086318-9da5-43bd-9672-ded65f3ddd0c') {
                      this.cards[3].bonus = 150000
                    } else if (decoded.id === '296d0bc6-7bcc-495c-a69d-de1b005aa716' || decoded.id === 'cff09594-77c9-429d-8273-da520b358744') {
                      this.cards[3].bonus = 50000
                    } else {
                      this.cards[3].bonus = 0
                    }

                    //local vars
                    let runningCounter = 0;

                    // reset data value
                    if(this.cards[2].totalNumber !== 0) this.cards[2].totalNumber = 0
                    if(this.cards[3].totalNumber !== 0) this.cards[3].totalNumber = 0
                    if(this.dailyHour !== 0) this.dailyHour = 0;
                    if(this.gajiCounted) this.gajiCounted = false;
                    if(this.cards[3].countedOvertime !== 0) this.cards[3].countedOvertime = 0

                    // set Boss weekly paycheck
                    if(data.timesheets[0].associatedUser.associatedRole.role === 'Boss') {
                      this.cards[3].totalNumber = 500000;
                      this.cards[3].multiplier = 1;
                    }

                    if(data.timesheets[0].associatedUser.associatedRole.role !== 'Boss' && this.checkSunday(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}))) {
                      this.cards[3].totalNumber += 1
                    }
                    
                    // loop through each data sheet to get weekly value for jam total and gaji
                    for(let i = 0; i < data.timesheets.length; i++) {
                      // check if no data is returned from the server
                      if(data.timesheets[i].hours === null && data.timesheets.length === 0) {
                        this.cards[2].totalNumber = 0
                        this.cards[3].totalNumber = 0
                      } else {
                        // adding hours to jam total
                        this.cards[2].totalNumber += data.timesheets[i].hours;

                        // since we're accessing i-1, to prevent error, we add this check to continue if i equals to 0

                        if(data.timesheets[0].associatedUser.associatedRole.role !== 'Boss') {
                          if(i === 0 || this.formatDate(data.timesheets[i].date) !== this.formatDate(data.timesheets[i-1].date)) {
                            this.dailyHour = data.timesheets[i].hours
                            
                            // we add overtime with running counter before we reset running counter below
                            // we add it up top because we have a if check down below to add it in the case that the first item is considered overtime.
                            this.cards[3].countedOvertime += runningCounter;

                            // reset running counter after assigning value to overtime
                            runningCounter = 0;
                            this.overtimeExceed = false;
                            this.gajiCounted = false;
                            if(parseInt(data.timesheets[i].hours) >= 3 && !this.checkSunday(this.formatDate(data.timesheets[i].date))) {
                              this.cards[3].totalNumber += 1
                              this.gajiCounted = true
                            }
                            /*
                              Logic to count overtime:
                              - max value is 9. if its more than 9, we don't calculate overtime and add max value to running counter (3)
                              - if its less than 5, since we reset it above, we just continue
                              - if it is more than 5, we add it to running counter and continue through the loop
                            */
                            if(data.timesheets[i].hours > 9.0) {
                              this.overtimeExceed = true;
                              runningCounter = 3;
                            } else if(data.timesheets[i].hours < 5.0) {
                              continue
                            } else {
                              runningCounter = parseInt((this.dailyHour - 3) / 2)
                            }
                          }
                          // added date check so we can calculate gaji
                          else if(this.formatDate(data.timesheets[i].date) === this.formatDate(data.timesheets[i-1].date) && !this.checkSunday(this.formatDate(data.timesheets[i].date))) {
                            this.dailyHour += data.timesheets[i].hours
                            // check if gaji hasn't been counted yet and daily hours exceed 3 hours
                            if(parseInt(this.dailyHour) >= 3 && !this.gajiCounted) {
                              this.cards[3].totalNumber += 1;
                              this.gajiCounted = true;
                            }
                            if(!this.overtimeExceed) {
                              // if daily hour is 9 hour and overtime has been counted, we continue
                              // The countedOvertime check is to prevent overtime not calculated. For example, if overtime counted is only 2, but the total hours for the day jump from 7 to 9.5, we should count that in
                              if(this.dailyHour > 9.0) {
                                this.overtimeExceed = true;
                                runningCounter = 3
                              } else if(this.dailyHour < 5.0) {
                                continue
                              } else {
                                runningCounter = parseInt((this.dailyHour - 3) / 2)
                              }
                            }
                          }
                          // check if its the last item in the array. if it is, then add the running counter.
                          if(i === data.timesheets.length - 1) {
                            this.cards[3].countedOvertime += runningCounter
                          }
                        }
                      }
                    }
                  } else {
                    if(decoded.id === '660abe9e-692d-45d4-b1f0-78f146f23b52' || decoded.id === '2e077815-2d61-4aa0-baa4-306c2e0a26bf' || decoded.id === 'e8738dbf-2c72-41f0-a389-36fe7df2c38f' || decoded.id === 'ba58f3dd-f9f4-4621-847c-a648d8eeac02' || decoded.id === '75d3a72a-2540-4223-b27e-9b193b7e609d' || decoded.id === '07ad27f8-0294-4b9d-b7f9-d4257b0ffcaf' || decoded.id === '00396799-b87c-47ff-b67b-4287033bdfc1') {
                      this.cards[3].bonus = 200000
                    } else if(decoded.id === '96e773e5-6c30-408e-9808-7d7df3d776f2' || decoded.id === '28e597cf-ebfd-41ab-95c2-c5477b11ba1b' || decoded.id === 'fc799143-55e8-4725-9113-788293dcb026' || decoded.id === '8a512ce2-c53f-45a3-bcfd-08669cfbe54c' || decoded.id === '8dcbd56c-d6ef-472c-8b8c-407f0398d2e0' ||
                    decoded.id === '18855034-7024-4b48-8890-68413a0801ac' ||
                    decoded.id === '9d02446a-7c9c-4fc8-9553-2f8e71e38802' ||
                    decoded.id === '5cf3a9b1-43a7-4229-86dd-95ebad0be46f' || decoded.id === '58274ef2-b4f0-4929-91d7-a4199b911c83' || decoded.id === '9eec3bf4-bd09-4896-bc3c-9a9c7af90e6e' || decoded.id === '0b1f18a4-750a-4fe2-a90c-5e3c928b023d' || decoded.id === '87f34017-628c-4aef-a141-e94ea185747e' || decoded.id === '62086318-9da5-43bd-9672-ded65f3ddd0c') {
                      this.cards[3].bonus = 150000
                    } else if (decoded.id === '296d0bc6-7bcc-495c-a69d-de1b005aa716' || decoded.id === 'cff09594-77c9-429d-8273-da520b358744') {
                      this.cards[3].bonus = 50000
                    } else {
                      this.cards[3].bonus = 0
                    }
                  }
                })
            }
          },
          setErrorMessage(msg) {
            this.errorMsg = msg;
          },
          setSuccessMessage(msg) {
            this.successMsg = msg;
            setTimeout(() => {
              this.successMsg = '';
            }, 5000)
          },
          checkSunday(date) {
            const dateObj = new Date(date)
            return dateObj.getDay() === 0 ? true : false
          },
          // formatDate(date) {
          //   if(process.env.NODE_ENV === 'production') {
          //     const yesterdayDate = new Date(date)
          //     const today = new Date(yesterdayDate.setDate(yesterdayDate.getDate() + 1))
          //     return today
          //   } else {
          //     const currentDate = new Date(date)
          //     return currentDate
          //   }
              
          // },
          async fetchIngameUser() {
            await this.fetchData('/users/all-ingame-user', 'GET', null, true)
              .then(res => {
                return res.json()
              })
              .then(data => {
                if(data.msg === undefined) {
                  this.kota =  data.allUser
                } else {
                  this.errorMsg = data.msg
                }
              })
          },
          async fetchUserRating() {
            let allRating;
            await this.fetchData('/rating/get-one-rating', 'GET', null, true)
              .then(res => {
                return res.json()
              })
              .then(data => {
                if(data.msg === undefined) {
                  allRating = data.allRating
                } else {
                  return
                }
                
              })
            if(allRating.length !== 0) {
              let runningSum = 0
              for(let i = 0; i < allRating.length; i++) {
                runningSum += allRating[i].rating
              }
              const total = runningSum / allRating.length
              if(total % 1 === 0) {
                this.totalRating = parseInt(total)
              } else {
                this.totalRating = parseFloat(total).toFixed(2)
              }
            } else {
              console.warn('Tidak dapat menemukan rating')
            }
            
          },
        },
        inject: ['fetchData', 'socket', 'fetchStatus']
    }
</script>

<style>
    main {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      overflow-y: auto;
      height: calc(100vh - 70px);
      min-height: 800px;
    }
    h1 {
        font-size: 3em;
        font-weight: 700
    }
    .light {
        font-size: 30px;
        color: darkGray
    }
    .card__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .card__container {
        background-color: white;
        border: 1px solid #E4E4E4;
        width: 24%;
        margin-top: 40px;
        text-align: center;
        border-radius: 10px;
        margin-right: 10px;
        height: 226px;
    }
    .body__flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        margin-top: 20px;
    }
    .body__flex--alt {
      margin-top: 0
    }
    .table {
      margin-right: 20px;
    }
    .button {
      width: 29%
    }
    .main__left {
      width: 80%;
      margin-right: 20px
    }
    .main__right {
      width: 20%;
      overflow: hidden;
      overflow-y: auto;
      height: 99%;
      background: #b5b5b5;
      border-radius: 10px;
    }

    .user__rating {
      font-size: 2em;
      display: flex;
      align-items: center;
    }

    .user__rating img {
      width: 23px;
      margin-left: 5px;
    }

    h2 {
      font-size: 1.5em;
      margin-top: 40px;
    }

    .leaderboard {
      margin-bottom: 20px;
    }

    .leaderboard .grid {
        display: grid;
        grid-template-columns: repeat(3, 200px);
        margin-top: 20px;
    }

    .leaderboard .grid__item {
      display: grid;
      grid-template-columns: repeat(3, 200px);
    }

    .leaderboard__flex {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .leaderboard__right {
      margin-left: 20px;
      font-size: 1.5em;
    }

    @media only screen and (max-width: 1000px) {
      .body__flex {
        display: block;
      }
      .table {
        margin-right: 0;
        width: 100%;
      }
      .button {
        width: 100%
      }
    }

    .error, .success {
      color: red;
      font-size: 1.6em;
      margin-top: 20px;
      font-weight: 700;
    }

    .success {
      color: green;
    }
</style>