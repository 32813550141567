<template>
    <li :class="activeClass" :name="navName"><img :src="iconSrc" :alt="alt"><slot></slot></li>
</template>

<script>
    export default {
        props: ['iconSrc', 'alt', 'activeClass', 'navName']
    }
</script>
<style scoped>
    li {
        margin: 20px;
        margin-right: 0;
        padding: 15px 10px;
        text-decoration: none;
        text-align: left;
        color: #fff;
        font-size: 20px;
        cursor: pointer;
        position: relative;
        padding-left: 20px;
        z-index: 2;
    }
    img {
        width: 25px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 20px;
    }

    .active {
        /* border-radius: 30px 0 0 30px;
        background-color: #EFF2FB; */
        color: rgb(0,95,252);
        position: relative;
    }

    .active img {
      -webkit-filter: invert(50%) sepia(96%) saturate(4203%) hue-rotate(214deg) brightness(99%) contrast(102%);
      filter: invert(50%) sepia(96%) saturate(4203%) hue-rotate(214deg) brightness(99%) contrast(102%);
    }

    .add img{
      margin-bottom: 5px
    }

    /* .active::after {
        content: '';
        content: "";
        position: absolute;
        
        background-color: #005ffc;
        top: -85px;
        height: 85px;
        width: 47px;
        right: 0px;
        border-bottom-right-radius: 40px;
        box-shadow: 0 45px 0 0 #EFF2FB;
        user-select: none;
        cursor: default;
        transform: translateY(0px);
        transition: transform 0.4s;
    }
    .active::before {
        content: attr(data-content);
        position: absolute;
        
        background-color: rgb(0,95,252);
        bottom: -85px;
        height: 85px;
        width: 47px;
        right: 0px;
        border-top-right-radius: 40px;
        box-shadow: 0 -45px 0 0 #EFF2FB;
        user-select: none;
        cursor: default;
        transform: translateY(0px);
        transition: transform 0.4s;
    } */
</style>