<template>
  <div class="grid__container" :class="customClass"><slot></slot></div>
</template>

<script>
  export default {
    props: ['customClass']
  }
</script>

<style>
  .grid__container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
  }
  .grid__container--alt {
    margin-top: 0;
    flex: 0 0 100%;
  }
</style>