<template>
    <!-- Will get all timesheet as a prop from DashboardTimeTable -->
    <!-- Will also get the ID of the user from prop-->
    <!-- Loop through the all timesheet array -->
    <!-- Display the table -->
    <div class="cell__container">
        <div class="grid">
          <div class="cell__first timetable__header">
            <div class="cell cell__header">Tanggal</div>
          </div>
          <div class="timetable__header">
            <div class="cell cell__header">Clock In</div>
          </div>
          <div class="timetable__header">
            <div class="cell cell__header">Clock Out</div>
          </div>
          <div class="timetable__header">
            <div class="cell cell__header">Total Jam</div>
          </div>
      </div>
      <div class="grid" v-for="(timesheet, i) in timesheets" :key="i">
        <!-- {{ isAfter(timesheet.associatedTimesheet.date, timesheets[i-1].associatedTimesheet.date) }} -->
        <div class="cell cell__total cell__total--left" v-if="i > 0 && 
        isAfter(timesheet.associatedTimesheet.date, timesheets[i-1].associatedTimesheet.date)"></div>
        <div class="cell cell__total cell__total--middle" v-if="i > 0 && isAfter(timesheet.associatedTimesheet.date, timesheets[i-1].associatedTimesheet.date)">Total Jam</div>
        <div class="cell cell__total cell__total--right" v-if="i > 0 && isAfter(timesheet.associatedTimesheet.date, timesheets[i-1].associatedTimesheet.date)"></div>
        <div class="cell cell__total" v-if="i > 0 && isAfter(timesheet.associatedTimesheet.date, timesheets[i-1].associatedTimesheet.date)">{{hoursToHM(timesheets[i-1].totalHours)}}</div>
        <div class="cell">{{formatDate(timesheet.associatedTimesheet.date)}}</div>
        <div class="cell">{{timesheet.associatedTimesheet.clockIn}}</div>
        <div class="cell">{{timesheet.associatedTimesheet.clockOut}}</div>
        <div class="cell">{{hoursToHM(timesheet.associatedTimesheet.hours)}}</div>
      </div>
      <div class="grid" v-if="timesheets.length !== 0">
        <div class="cell cell__total cell__total--left"></div>
        <div class="cell cell__total cell__total--middle">Total Jam</div>
        <div class="cell cell__total cell__total--right"></div>
        <div class="cell cell__total">{{hoursToHM(timesheets[timesheets.length - 1].totalHours)}}</div>
      </div>
      <div class="grid" v-if="timesheets.length !== 0">
        <div class="cell cell__total cell__total--left"></div>
        <div class="cell cell__total cell__total--middle">Total Jam 1 Minggu</div>
        <div class="cell cell__total cell__total--right"></div>
        <div class="cell cell__total">{{hoursToHM(timesheets[timesheets.length - 1].runningTotalCount)}}</div>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
    // date fix
    /*
    formatDate(timesheet.associatedTimesheet.date) !== formatDate(timesheets[i-1].associatedTimesheet.date)
    */
    export default {
        data() {
            return {
                timesheets: this.timesheet,
                loading: true,
            }
        },
        watch: {
            timesheet() {
              this.loading = true
              const userId = this.userId
              var newArr = this.timesheet.filter(function(el) {
                  return el.userId === userId
              })
              this.addTotalHours(newArr)
              this.timesheets = newArr

              this.loading = false
            }
        },
        mounted() {
            this.loading = true
            const userId = this.userId
            var newArr = this.timesheet.filter(function(el) {
                return el.userId === userId
            })
            newArr = this.addTotalHours(newArr)
            this.timesheets = newArr
            this.loading = false
        },
        methods: {
            isAfter(date1, date2) {
              const index1 = date1.indexOf('T');
              const index2 = date2.indexOf('T');
              var d1 = date1.substring(0, index1)
              var d2 = date2.substring(0, index2);
              return moment(d1).isAfter(d2, 'day')
            },
            formatDate(date) {
              return moment(date).utc().format('DD/MM/YYYY')
            },
            // incrementCount(totalHours) {
            //     this.runningCount += totalHours
            // },
            // resetCount() {
            //     this.runningCount = 0;
            // },
            addTotalHours(arr) {
                if(arr.length !== 0) {
                    let runningCount = 0;
                    let runningTotalCount = 0;
                    for(let i = 0; i < arr.length; i++) {
                        if(i > 0) {
                            if(this.formatDate(arr[i].associatedTimesheet.date) !== this.formatDate(arr[i-1].associatedTimesheet.date)) {
                                runningCount = 0;
                            }
                        }
                        runningCount += arr[i].associatedTimesheet.hours
                        runningTotalCount += arr[i].associatedTimesheet.hours
                        arr[i].totalHours = runningCount
                        arr[i].runningTotalCount = runningTotalCount
                    }
                }
                return arr
            },
            hoursToHM(totalHours) {
              const hours = Math.trunc(totalHours)
              const minutes = parseInt((totalHours%1) * 60)
              if(hours === 0) {
                return minutes + ' menit'
              } else {
                return hours + ' jam ' + minutes + ' menit';
              }
            }
        },
        props: ['timesheet', 'userId'],

    }
</script>

<style scoped>
    .cell__container {
      margin-top: 20px
    }
    .cell {
      border-top: none;
    }

    .cell__total--middle {
        border-left: none;
        border-right: none;
    }
    
    .cell__total--left {
        border-right: none
    }
</style>