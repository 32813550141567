<template>
  <div>
    
    <div class="timetable__container" v-for="user in users" :key="user.firstName + user.lastName" >
        <h2>{{user.firstName}} {{user.lastName }} - {{user.associatedRole.role}}</h2>
        <timetable-table :timesheet="allTimeTable" :user-id="user.id"></timetable-table>
    </div>
  </div>
</template>

<script>
    import TimetableTable from './TimetableTable.vue'
    export default {
        components: {
            TimetableTable
        },
        props: ['allTimeTable', 'users'],
    }
</script>