<template>
  <div class="container">
    <h1>Add User</h1>
    <add-user-form></add-user-form>
  </div>
</template>

<script>
  import AddUserForm from './AddUserForm.vue'
  export default {
    components: {
      AddUserForm
    }
  }
</script>