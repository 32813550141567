<template>
    <header>
        <logo @render-navigation="renderNavigation" custom-class="dashboard__logo" div-class="dashboard__header" img-src="./img/logo-trans-white.png"></logo>
    </header>
</template>

<script>
    import Logo from './Logo.vue'
    export default {
        components: {
            Logo
        },
        inject: ['renderNavigation']
    }
</script>

<style scoped>
   header {
    padding-top: 30px
   }
</style>
