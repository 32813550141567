<template>
  <li :class="activeClass"><slot></slot></li>
</template>

<script>
  export default {
    props: ['activeClass']
  }
</script>

<style scoped>
  li {
    text-decoration: none;
    display: flex;
    font-size: 1.3em;
    margin-right: 20px;
    position: relative;
    z-index: 2;
    height: 40px;
    align-items: center;
    margin: 0 35px;
    cursor: pointer;
  }

  .active {
    color: #fff;
  }
</style>

