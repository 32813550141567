<template>
    <div class="cell cell__left">{{driverName}}</div>
    <div class="cell">{{totalRating}}<img src="/img/yellow-star.svg" class="yellow_star" alt="yellow star image" /> <span>({{jumlahRating}})</span></div>
</template>

<script>
    export default {
        props: ['driverName', 'allRating', 'userId'],
        data() {
            return {
                totalRating: 0,
                jumlahRating: 0,
            }
        },
        mounted() {
            const userId = this.userId
            var newArr = this.allRating.filter(function(el) {
                return el.userId === userId
            })

            this.totalRating = this.countRating(newArr)
            this.jumlahRating = newArr.length;
        },
        methods: {
            countRating(arr) {
                if(arr.length !== 0) {
                    let runningSum = 0;
                    for(let i = 0; i < arr.length; i++) {
                        runningSum += arr[i].rating
                    }
                    const total = runningSum / arr.length
                    if(total % 1 === 0) {
                      return parseInt(total)
                    } else {
                      return parseFloat(total).toFixed(2) 
                    }
                    
                }
                return 0
            }
        }
    }
</script>

<style>
  .yellow_star {
    width: 15px;
    margin-left: 2px;
  }
  span {
    margin-left: 10px;
  }
</style>
