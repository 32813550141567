<template>
  <div id="half">
    <half-left></half-left>
    <half-right></half-right>
  </div>
</template>

<script>
import HalfLeft from '../Containers/HalfLeft.vue'
import HalfRight from '../Containers/HalfRight.vue'
  export default {
    name: 'home-view',
    components: {
      HalfLeft,
      HalfRight,
    },
  }
</script>

<style>
  #half {
    overflow: hidden;
  }
</style>