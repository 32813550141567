<template>
  <form @submit.prevent>
    <div v-if="err">
      <p class="error error__text">{{err}}</p>
    </div>
    <div v-if="successMessage">
      <p class="success">{{successMessage}}</p>
    </div>
    <div class="form__control">
      <label>Role:</label>
      <p>{{role}}</p>
    </div>
    <div class="form__control">
      <label for="namaDepan">Nama Depan:</label>
      <base-input v-model="firstName" :disabled="firstName !== ''" name="namaDepan" mode="text" placeholder-text="Silahkan masukan nama depan anda" custom-class="profile"></base-input>
    </div>
    <div class="form__control">
      <label for="namaBelakang">Nama Belakang:</label>
      <base-input v-model="lastName" :disabled="lastName !== ''" name="namaBelakang" mode="text" placeholder-text="Silahkan masukan nama belakang anda" custom-class="profile"></base-input>
    </div>
    <div class="form__control">
      <label for="email">Email:</label>
      <base-input v-model="email" :disabled="email !== ''" name="email" mode="text" placeholder-text="Silahkan masukan email anda" custom-class="profile"></base-input>
    </div>
    <div class="form__control">
      <label for="current-password" :class="{'error__text': err !== ''}">Password:</label>
      <base-input v-model="password" :class="{'error__profile': err !== ''}" name="current-password" mode="password" placeholder-text="Silahkan masukan password lama anda" custom-class="profile" autocomplete="on"></base-input>
    </div>
    <div class="form__control">
      <label for="new-password" :class="{'error__text': err !== ''}">New Password:</label>
      <base-input v-model="newPassword" @change="resetMsg()" :class="{'error__profile': err}" name="new-password" mode="password" placeholder-text="Silahkan masukan password baru anda" custom-class="profile" autocomplete="on"></base-input>
    </div>
    <div class="form__control">
      <label for="confirm-password" :class="{'error__text': err !== ''}">Confirm New Password:</label>
      <base-input v-model="confirmPassword" @change="resetMsg()" :class="{'error__profile': err}" name="confirm-password" mode="password" placeholder-text="Silahkan masukan password baru anda sekali lagi" custom-class="profile" autocomplete="on"></base-input>
    </div>
    <div class="form__control form__control--button">
      <base-button @click="resetValue()" :class="{'animate': changed, 'revertAnimate': !changed}" custom-class="profile__button profile__discard">Discard</base-button>
      <base-button @click="checkValue()" @change="resetMsg()" custom-class="profile__button">Save</base-button>
    </div>
  </form>
</template>

<script>
  import BaseInput from '../UI/BaseInput.vue'
  import BaseButton from '../UI/BaseButton.vue'
  import jwt_decode from 'jwt-decode'
  export default {
    components: {
      BaseInput,
      BaseButton
    },
    data() {
      return {
        role: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        newPassword: '',
        confirmPassword: '',
        changed: false,
        err: '',
        successMessage: '',
      }
    },
    watch: {
      password(newVal) {
        newVal !== '' ? this.resetMsg() : '';
        if(this.newPassword !== '' || this.confirmPassword !== '') {
          this.changed = true;
        } else {
          this.changed = newVal !== '' ? true : false
        }
      },
      newPassword(newVal) {
                newVal !== '' ? this.resetMsg() : '';
        if(this.password !== '' || this.confirmPassword !== '') {
          this.changed = true;
        } else {
          this.changed = newVal !== '' ? true : false
        }
      },
      confirmPassword(newVal) {
        newVal !== '' ? this.resetMsg() : '';
        if(this.password !== '' || this.newPassword !== '') {
          this.changed = true;
        } else {
          this.changed = newVal !== '' ? true : false
        }
      },
    },
    inject: ['fetchData'],
    async mounted() {
      this.err =  '';
      this.successMessage = '';
      const decoded = jwt_decode(localStorage.getItem('_rToken'))
      if(decoded) {
        await this.fetchData(`/users/find/${decoded.id}`, 'GET', null, true)
          .then(res => {
            return res.json();
          })
          .then(data => {
            if(data.msg) {
              return
            }
            this.role = data.user.associatedRole.role;
            this.firstName = data.user.firstName;
            this.lastName = data.user.lastName;
            this.email = data.user.email;
          })
      }
    },
    methods: {
      resetValue() {
        this.password = '';
        this.newPassword = '';
        this.confirmPassword = '';
      },
      resetMsg() {
        this.err = '';
        this.successMessage = '';
      },
      checkValue() {
        const decoded = jwt_decode(localStorage.getItem('_rToken'))
        if(this.password !== '' && this.newPassword !== '' && this.confirmPassword !== '' && this.newPassword === this.confirmPassword) {
          this.fetchData(`/users/edit/${decoded.id}`, 'PUT', JSON.stringify({password: this.password, newPassword: this.newPassword}), true)
            .then(res => {
              if(res.status === 203) {
                this.err = '';
                this.password = '';
                this.newPassword = '';
                this.confirmPassword = '';
                this.successMessage = 'Berhasil mengupdate password!';
                return
              }
              return res.json();
            })
            .then(data => {
              if(data !== undefined) {
                this.successMessage = '';
                throw Error(data.msg)
              }
            })
            .catch(err => {
              this.err = err
            })
        } else if(this.password === '' || this.newPassword === '' || this.confirmPassword === '') {
          this.err = 'Kolom Password, New Password, atau Confirm Password tidak boleh kosong!';
        } else if(this.newPassword !== this.confirmPassword) {
          this.err = 'Koloh New Password dan Confirm Password tidak sama!';
        }
      } 
    },
    
  }
</script>

<style scoped>
  label {
    font-size: 1.5em;
    width: 100%;
    max-width: 300px;
  }

  .form__control {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin-bottom: 30px;
  }

  .form__control--button {
    width: 100%;
    justify-content: space-between;
  }

  .revertAnimate {
    opacity: 0;
    scale: 0;
  }

  .animate {
    opacity: 1;
    scale: 1;
  }

  .animate, .revertAnimate {
    transition: all .2s;
  }

  .error__profile, .error__text {
    border: solid 1px red;
    color: red;
  }

  .error__text {
    border: none
  }

  .error, .success {
    color: red;
    font-weight: 700;
    text-align: left;
  }

  .success {
    color: green;
    margin-bottom: 20px;
  }

  .error__text {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
    font-size: 1.3em;
  }
</style>