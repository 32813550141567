<template>
  <div>
    <div class="timetable__container">
        <h2>{{users.firstName}} {{users.lastName}} - {{users.associatedRole.role}}</h2>
        <timetable-table :timesheet="allTimeTable" :user-id="users.id"></timetable-table>
    </div>
  </div>
</template>
<script>
    import TimetableTable from './TimetableTable.vue'
    export default {
        components: {
            TimetableTable
        },
        props: ['allTimeTable', 'users'],
    }
</script>
<style>
    .timetable__container h2 {
        font-size: 1.8em;
        text-decoration: underline;
        font-weight: 600;
    }

    .timetable__container {
        margin: 20px 0;
    }
</style>