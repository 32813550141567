<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="left__header" :class="divClass">
    <img :src="imgSrc" alt="Logo Image" :class="customClass" @load="rerenderNav"/>
  </div>
</template>

<script>
  export default {
    props: {
      customClass: String, 
      divClass: String,
      imgSrc: {
        type: String,
        default: "./img/logo-trans-orange.png"
      }
    },
    methods: {
      rerenderNav() {
        if(this.$route.name !== 'home') {

          this.$emit('render-navigation')
        }
      }
    }
  }
</script>

<style scoped>
  img {
    width: 250px

  }

  .left__header {
    text-align: left;
  }

  .dashboard__logo {
    width: 150px
  }

  .dashboard__header {
    text-align: center
  }
  
  
</style>