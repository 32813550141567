import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../View/HomeView.vue';
import DashboardView from '../View/DashboardView.vue';
import DashboardRating from '../Control/DashboardRating.vue';
import jwt_decode from "jwt-decode";

const routes = [
  { path: '/', component: HomeView, name: 'home', meta: { hideForAuth: true } },
  { path: '/dashboard', component: DashboardView, name: 'dashboard',meta: { requiresAuth: true },},
  { path: '/profile', component: DashboardView, name: 'profile',meta: { requiresAuth: true },},
  { path: '/time-table', component: DashboardView, name: 'time-table',meta: { requiresAuth: true },},
  { path: '/add-user', component: DashboardView, name: 'add-user', meta: {requiresAuth: true, adminOnly: true}},
  { path: '/change-role', component: DashboardView, name: 'change-role', meta: {requiresAuth: true, adminOnly: true,}},
  { path: '/all-rating', component: DashboardView, name: 'all-rating', meta: {requiresAuth: true, adminOnly: true,}},
  { path: '/rating', component: DashboardRating, name: 'rating'},
  { path: '/car-log', component: DashboardView, name: 'car-log', meta: {requiresAuth: true, adminOnly: true,}},
  { path: "/:pathMatch(.*)*", name: "NotFound", meta: { notFound: true }}
]

function titleCase(str) {
  return str.toLowerCase().split('-').map(function(word) {
    return word.replace(word[0], word[0].toUpperCase());
  }).join(' ');
}

const router = createRouter({
  mode: 'history',
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) =>
{
  const  token  = localStorage.getItem('_rToken');

  if(token) {
    /*
      check if refresh token is expired through jwt_decode.
      If refresh token is expired, it will be removed from the local storage and send user back to login page.
      Refresh token expires every 30 days and user will get a new refresh token through logging in.
    */
    
    try {
      const decode = jwt_decode(token)
      if(decode.exp*1000 < new Date().getTime()) {
        localStorage.removeItem('_rToken')
        document.title = 'Homepage | Trans Indopride'
        next({path: '/'})
      }
    } catch(err) {
      localStorage.clear()
      document.title = titleCase(to.name) + " | Trans Indopride"
    }
  }
  

  if (to.meta.requiresAuth)
  {
    // page requires authentication - if there is none, redirect to /login
    if(to.meta.adminOnly) {
      let decoded;
      try {
        decoded = jwt_decode(token)
      } catch(err) {
        document.title = 'Home | Trans Indopride'
        next({path: '/'})
      }
      
      if (decoded.isAdmin) {
        document.title = titleCase(to.name) + " | Trans Indopride"
        next();
      } else if(token) {
        document.title = 'Dashboard | Trans Indopride'
        next({path: '/dashboard'});
      }
    } else {
      if (token) {
        document.title = titleCase(to.name) + " | Trans Indopride"; 
        next();
      }
      else {
        document.title = 'Homepage | Trans Indopride'
        next({path: '/'});
      }
    }
    
  }
  else if (to.meta.hideForAuth) {
    if (token) {
      document.title = 'Dashboard | Trans Indopride'
      next({path: '/dashboard'});
    }
    
    else {
      document.title = titleCase(to.name) + " | Trans Indopride"; 
      next();
    }
  }
  else if(to.meta.notFound) {
    if(token) {
      next({path: '/dashboard'})
    } else {
      next({path: '/'})
    }
  }
  else 
  {
    // Login is supposedly public - skip navigation if we have a token
    if (token ? to.path !== '/login' : true) {
      document.title = titleCase(to.name) + " | Trans Indopride"; 
      next()
    } else {
      next({path: '/'})
    }
  }
});

export default router;
