<template>
  <div class="cell cell__first">{{user.firstName}} {{user.lastName}}</div>
  <div class="cell">{{totalMobil}}</div>
  <div class="cell">{{totalMotor}}</div>
</template>

<script>
  export default {
    props: ['user', 'allCarLog'],
    data() {
      return {
        totalMotor: 0,
        totalMobil: 0,
      }
    },
    mounted() {
      this.getTotalKendaraan(this.user.id)
    },
    methods: {
      getTotalKendaraan(userId) {
        var newArr = this.allCarLog.filter(function(el) {
          return el.userId === userId
        })
        var motor = 0;
        var mobil = 0;
        for(let i = 0; i < newArr.length; i++) {
          if(newArr[i].carType === 'Motor') {
            motor++
          } else if(newArr[i].carType === 'Mobil') {
            mobil++
          }
        }
        this.totalMotor = motor;
        this.totalMobil = mobil;
      }
    }
  }
</script>

<style scoped>
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
</style>