<template>
  <div class="half__container">
      
  </div>
</template>

<style scoped>
  .half__container {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url('../../../public/img/Foto_20.png') 40% 0/cover no-repeat;
    width: 50%;
    height: 100vh
  }
</style>