
<template>
  <input :type="mode" :placeholder="placeholderText" :value="modelValue" @input="handleInput" :class="customClass"/> 
</template>

<script>
  export default {
    props: ['mode', 'placeholderText', 'modelValue', 'customClass'],
    emits: ['update:modelValue'],
    // data() {
    //   return {
    //     content: this.modelValue
    //   }
    // },
    methods: {
      handleInput(e) {
        this.$emit('update:modelValue', e.target.value)
      }
    }
  }
</script>

<style scoped>
  input {
    width: 100%;
    border: solid 1px #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 35px;
    box-sizing: border-box
  }

  .profile {
    margin: 0
  }

  .error {
    border: solid 1px red;
  }
</style>

