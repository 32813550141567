<template>
  <label for="input-name"><slot></slot></label>
</template>

<script>
  export default {
    props: ['inputName']
  }
</script>

<style scoped>
  label {
    font-size: 1.2em;

  }
</style>