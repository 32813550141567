import { createApp } from 'vue'
import VueHtml2Canvas from 'vue-html2canvas';
/* eslint-disable */import babelPolyfill from 'babel-polyfill'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue'

// router

import router from './components/Router/index.js';

import BaseInput from './components/UI/BaseInput.vue'
import BaseLabel from './components/UI/BaseLabel.vue'
import BaseButton from './components/UI/BaseButton.vue'
// import './registerServiceWorker'

const app = createApp(App)

app.use(router);
app.use(VueHtml2Canvas);
app.use(Antd);

app.component('base-input', BaseInput)
app.component('base-label', BaseLabel)
app.component('base-button', BaseButton)

app.mount('#app')

