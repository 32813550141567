<template>
  <div class="left">
    <div class="left__container">
      <logo></logo>
      <h1>Log in</h1>
      <h3>Selamat Datang! Silahkan masukan akun Trans anda.</h3>
      <my-form></my-form>
    </div>
  </div>
</template>

<script>
import Logo from '../Control/Logo.vue'
import MyForm from '../Control/MyForm.vue'

  export default {
    components: {
      Logo,
      MyForm
    },
    data() {
      return {
        errorMessage: [],
      }
    },
  }
</script>

<style scoped>
  .left {
    width: 50%;
    height: 100vh;
  }

  .left__container {
    padding: 80px 18%;
  }

  h1 {
    margin-top: 20%;
    font-size: 3em;
    text-align: left;
    margin-bottom: 10px;
  }

  h3 {
    color: #58595B;
    font-size: 1.2em;
    font-weight: 400;
    margin-bottom: 45px;
    margin-top: 0;
  }

</style>