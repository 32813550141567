<template>
    <div class="date__container" :class="{'date__container--alt': isAdmin}">
        <div class="timetable__nav__container">
            <!-- put 3 of the button here and  call function in the parent to render the component -->
            <base-small-nav name="TimeTableSelf" :class="{active: activeComponent === 'TimeTableSelf'}" @click="renderComponent('TimeTableSelf')">Timesheet</base-small-nav>
            <base-small-nav v-if="isAdmin" name="TimeTableAll" :class="{active: activeComponent === 'TimeTableAll'}" @click="renderComponent('TimeTableAll')">All Timesheet</base-small-nav>
            <base-small-nav v-if="isAdmin" name="TimeTableInsentif" :class="{active: activeComponent === 'TimeTableInsentif'}" @click="renderComponent('TimeTableInsentif')">Insentif</base-small-nav>
            <base-small-nav name="TimeTableRating" :class="{active: activeComponent === 'TimeTableRating'}" @click="renderComponent('TimeTableRating')">Rating Log</base-small-nav>
            <i class="timetable__marker" :style="{left: left + 'px'}"></i>
        </div>
        
        <Datepicker class="date__picker" v-if="activeComponent !== 'TimeTableInsentif'" v-model="date" range auto-range="6" :enableTimePicker="false"></Datepicker>
        <Datepicker class="date__picker" v-else v-model="date" :disabledWeekDays="[1,2,3,4,5,6]" :enableTimePicker="false"></Datepicker>
    </div>
</template>

<script>
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';
    import BaseSmallNav from '../UI/BaseSmallNav.vue';
    import moment from 'moment';
    export default {
        components: {
            Datepicker,
            BaseSmallNav
        },
        props: ['isAdmin'],
        data() {
            return {
                date: [],
                activeComponent: 'TimeTableSelf',
                left: 0,  
            }
        },
        watch: {
            async date(newVal) {
                // this will fetch timesheet again to re-calculate insentif based on last week monday until sunday
                // will also fetch insentif table to check who has gotten their isentif and who hasn't
                // but all of the methods needs to be set in the parent component, this component just update the date present
                if(this.activeComponent === 'TimeTableInsentif') {
                  const sunday = moment(new Date(newVal).toISOString()).utcOffset(0, true).format('YYYY-MM-DD')
                  const monday = moment(new Date(newVal).toISOString()).utcOffset(0, true).startOf('isoweek').format('YYYY-MM-DD')
                  await this.fetchData(`/timesheet/${monday}/${sunday}`, 'GET', null, 'true')
                    .then(res => {
                        return res.json()
                    })
                    .then(data => {
                        this.$parent.updateTable(data.timesheet)
                        this.$parent.setInsentifDate(sunday);
                        this.$parent.setLastMonday(monday)
                    })
                } 
                else if (this.activeComponent === 'TimeTableRating') {
                  const date1 = moment(new Date(newVal[0]).toISOString()).utcOffset(0, true).format()
                  const date2 = moment(new Date(newVal[1]).toISOString()).utcOffset(0, true).format()
                  await this.fetchData(`/rating/all-rating?startDate=${this.reformatDate(date1)}&endDate=${this.reformatDate(date2)}`, 'GET', null, true)
                  .then(res => {
                    return res.json()
                  })
                  .then(data => {
                    this.$parent.updateTable(data.allRating)
                  })
                  
                }
                else {
                  const date1 = moment(new Date(newVal[0]).toISOString()).utcOffset(0, true).format()
                  const date2 = moment(new Date(newVal[1]).toISOString()).utcOffset(0, true).format()
                  await this.fetchData(`/timesheet/${this.reformatDate(date1)}/${this.reformatDate(date2)}`, 'GET', null, true)
                    .then(res => {
                        return res.json()
                    })
                    .then(data => {
                        this.$parent.updateTable(data.timesheet)
                    })
                }
                
                
            },
            activeComponent(newVal) {
                if(newVal === 'TimeTableInsentif') {
                    this.date = this.getThisWeekSunday()
                    this.$parent.setInsentifDate(this.getThisWeekSunday());
                } else {
                    this.date = this.getCurrentWeek()
                }
            }
        },
        methods: {
            renderComponent(name) {
              this.activeComponent = name
              if(this.isAdmin) {
                this.left = name === 'TimeTableSelf' ? 0 : name === 'TimeTableAll' ? 184 : name === 'TimeTableInsentif' ? 363 : 525
              } else {
                this.left = name === 'TimeTableSelf' ? 0 : 170
              }
              
              this.$parent.setActiveComponent(name)
            },
            getCurrentWeek() {
                const today = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
                var first 
                var last
                
                // check if today is Sunday to avoid miss calculation of timesheet into next week
                if(today.getDay() === 0) {
                  first = today.getDate() - today.getDay() - 6;
                  last = first + 6
                } else {
                  first = today.getDate() - today.getDay() + 1;
                  last = first + 6
                }
              
                // set date to monday and sunday  
                const monday = new Date(new Date(today.setDate(first)));
                const sunday = new Date(new Date(today.setDate(last)));

                return [monday, sunday];
            },
            getThisWeekSunday(date) {
              if(date) return new Date(new Date(date).toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
              // put today's date in a variable
              var today = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
              
              // check if today is sunday. If it is, then just return the date
              if(today.getDay() === 0) return today

              // calculation to get this week sunday
              today = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
              const lastSunday = today.getDate() - today.getDay() + 7
              return new Date(today.setDate(lastSunday))
            },
            getLastMonday(date) {
              var today
              
                if(date === undefined) {
                  today = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
                } else {
                  today = new Date(new Date(date).toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
                }
                const lastMonday = today.getDate() - today.getDay() - 6;

                return new Date(today.setDate(lastMonday))
            },
            reformatDate(date) {
              const d = new Date(date)
              var month = d.getUTCMonth() + 1; //months from 1-12
              var day = d.getUTCDate();
              var year = d.getUTCFullYear();
              day = day < 10 ? '0'+day : day;
              month = month < 10 ? '0'+month : month;
              return year + "-" + month + "-" + day;
            }
        },
        mounted() {
            this.date = this.getCurrentWeek();
        },
        inject: ['fetchData']
    }
</script>

<style>
    .date__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
    }

    .date__picker {
      width: 255px;
    }

    .timetable__nav__container {
      position: relative;
      display: flex;
    }

    .timetable__marker {
      background: rgb(0,95,252);
      height: 40px;
      display: block;
      width: 165px;
      position: absolute;
      border-radius: 20px;
      top: 0px;
      transition: left 0.2s;
    }
</style>