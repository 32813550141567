<template>
    <div class="container">
        <dashboard-main></dashboard-main>
    </div>
</template>

<script>
    import DashboardMain from './DashboardMain.vue'
    export default {
        components: {
            DashboardMain
        }
    }
</script>

<style scoped>
    .grid__container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: auto;
        grid-template-areas:
            "header"
            "main main2 main2 main3";
    }

</style>