<template>
    <div class="half">
        
        <keep-alive>
            <div class="dashboard__left">
                <dashboard-logo></dashboard-logo>
                <dashboard-nav v-if="showNav"></dashboard-nav>
            </div>
        </keep-alive>
        <div class="dashboard__right">
          <dashboard-header></dashboard-header>
          <dashboard-home class="container" :key="dashboardKey" v-if="$route.name === 'dashboard'"></dashboard-home>
          <dashboard-profile class="container" :key="profileKey" v-if="$route.name === 'profile'"></dashboard-profile>
          <dashboard-time-table class="container" :key="timetableKey" v-if="$route.name === 'time-table'"></dashboard-time-table>
          <dashboard-add-user class="container" :key="addUserKey" v-if="$route.name === 'add-user'"></dashboard-add-user>
          <dashboard-change-role class="container" :key="changeRoleKey" v-if="$route.name === 'change-role'"></dashboard-change-role>
          <dashboard-all-rating class="container" :key="changeAllRatingKey" v-if="$route.name === 'all-rating'"></dashboard-all-rating>
          <dashboard-car-log class="container" :key="changeAllRatingKey" v-if="$route.name === 'car-log'"></dashboard-car-log>
        </div>
    </div>
</template>

<script>
    import DashboardLogo from '../Control/DashboardLogo.vue'
    import DashboardNav from '../Control/DashboardNav.vue'
    import DashboardHome from '../Control/DashboardHome.vue';
    import DashboardProfile from '../Control/DashboardProfile.vue';
    import DashboardHeader from '../Control/DashboardHeader.vue';
    import DashboardTimeTable from '../Control/DashboardTimeTable.vue';
    import DashboardAddUser from '../Control/DashboardAddUser.vue';
    import DashboardChangeRole from '../Control/DashboardChangeRole.vue';
    import DashboardAllRating from '../Control/DashboardAllRating.vue';
    import DashboardCarLog from '../Control/DashboardCarLog.vue';
    import { message } from 'ant-design-vue';
    export default {
        components: {
            DashboardLogo,
            DashboardNav,
            DashboardHome,
            DashboardProfile,
            DashboardHeader,
            DashboardTimeTable,
            DashboardAddUser,
            DashboardChangeRole,
            DashboardAllRating,
            DashboardCarLog,
        },
        data() {
            return {
                isOnDuty: false,
                showNav: false,
                route: '',
                dashboardKey: 0,
                profileKey: 0,
                timetableKey: 0,
                addUserKey: 0,
                changeRoleKey: 0,
                changeAllRatingKey: 0,
                registration: null,
                updateExists: false,
                refreshing: false,
            }
        },
        computed: {
          viewRoute() {
            return this.$route.name.charAt(0).toUpperCase() + this.$route.name.slice(1);
          }
        },  
        created() {
          document.addEventListener('swUpdated', this.updateAvailable, { once: true })
          if (navigator.serviceWorker) {  
            navigator.serviceWorker.addEventListener(
              'controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
              }
            );
          }
        },
        mounted() {
            window.addEventListener('beforeunload', this.popupWindow, { capture: true });
        },
        methods: {
          async updateAvailable(event) {
            this.registration = event.detail
            this.updateExists = true
            message.info('New content is available; Refreshing page in 10 seconds.', 8)
            await new Promise(resolve => setTimeout(resolve, 10000));
            this.registration.waiting.postMessage('skipWaiting');
          },
          toggleOnDuty(status) {
              this.isOnDuty = status
          },
          popupWindow(e) {
              if(!this.isOnDuty) return
              e.preventDefault()
              e.returnValue = "Anda masih on duty. Jangan lupa untuk off duty sebelum keluar!";
          },
          renderNavigation() {
              this.showNav = true;
          },
          refresh(route) {
            switch(route) {
              case 'dashboard':
                this.dashboardKey++
                break
              case 'profile':
                this.profileKey++
                break
              case 'time-table':
                this.timetableKey++
                break
              case 'add-user':
                this.addUserKey++
                break
              case 'change-role':
                this.changeRoleKey++
                break
              case 'rating':
                this.changeRatingKey++
                break
            }

          },
        },
        provide() {
            return {
                toggleOnDuty: this.toggleOnDuty,
                renderNavigation: this.renderNavigation,
            }
        }
    }
</script>

<style scoped>
    .half {
        display: flex;
        background: #EFF2FB;
        overflow: hidden;
        height: 100vh;
    }
    .dashboard__left {
        width: 20%;
        background: rgb(0,95,252);
        height: 100vh;
        position: relative;
        z-index: 0;
        padding-left: 20px;
        min-width: 250px;
    }
    .dashboard__right {
        width: 80%;
        background: linear-gradient(to right, #EFF2FB, #F5F7FB);
        overflow: hidden;
        overflow-y: auto;
    }

    .container {
      padding: 0 30px;
      padding-right: 0;
      overflow: hidden;
      padding-right: 10px;
    }
</style>

