<template>
    <ul>
        <base-nav :active-class="{active: $route.name == 'dashboard'}" ref="dashboard" nav-name="dashboard" icon-src="./img/icons8-home.svg" alt="House Logo" @click="goTo('/dashboard')">Home</base-nav>
        <base-nav :active-class="{active: $route.name == 'profile'}" ref="profile" nav-name="profile" icon-src="./img/account.svg" alt="Profile Logo" @click="goTo('/profile')">Profile</base-nav>
        <base-nav :active-class="{active: $route.name == 'time-table'}" ref="time-table" nav-name="time-table" icon-src="./img/timetable-icon.svg" alt="Timetable Logo" @click="goTo('/time-table')">Timetable</base-nav>
        <base-nav :active-class="{active: $route.name == 'add-user', add: true}" ref="add-user" nav-name="add-user" icon-src="./img/add-user.svg" alt="Add User Logo" @click="goTo('/add-user')" v-if="isAdmin">Add User</base-nav>
        <base-nav :active-class="{active: $route.name == 'change-role'}" ref="change-role" nav-name="change-role" icon-src="./img/user-role.svg" alt="Change Role Logo" @click="goTo('/change-role')" v-if="isAdmin">Change Role</base-nav>
        <base-nav :active-class="{active: $route.name == 'all-rating'}" ref="all-rating" nav-name="all-rating" icon-src="./img/all-rating.svg" alt="All Rating Logo" @click="goTo('/all-rating')" v-if="isAdmin">All Rating</base-nav>
        <base-nav :active-class="{active: $route.name == 'car-log'}" ref="car-log" nav-name="car-log" icon-src="./img/taxi-icon.svg" alt="Taxi Icon" @click="goTo('/car-log')" v-if="isAdmin">Car Log</base-nav>
        <i class="marker" :style="{'top': topOffset + 'px'}"></i>
    </ul>
    
</template>

<script scoped>
    import BaseNav from '../UI/BaseNav.vue';
    import jwt_decode from 'jwt-decode';
    export default {
        components: {
            BaseNav
        },
        data() {
            return {
                topOffset: 130,
                isAdmin: jwt_decode(localStorage.getItem('_rToken')).isAdmin
            }
        },
        // computed: {
        //     calculateTopOffset() {
        //         // const el = this.$el.querySelector('li[name="' + this.$route.name + '"]')
        //         // return el.getBoundingClientRect().top;
        //     }
        // },
        mounted() {
            const el = this.$el.querySelector('li[name="' + this.$route.name + '"]')
            this.topOffset = el.getBoundingClientRect().top;
            this.$nextTick(() => {
                switch(this.$route.name) {
                    case 'profile':
                        this.topOffset += 5
                        break;
                    case 'time-table':
                        this.topOffset += 10
                        break;
                    case 'add-user':
                      this.topOffset += 15
                      break;
                    case 'change-role':
                      this.topOffset += 22
                      break;
                    case 'all-rating':
                      this.topOffset += 29
                      break;
                    case 'car-log':
                      this.topOffset += 36
                      break;
                }
            })
        },
        methods: {
            goTo(path) {
                this.$router.push(path)
                const ref = path.substr(1,)
                const el = this.$el.querySelector('li[name="' + ref + '"]')
                this.topOffset = el.getBoundingClientRect().top
            }

        }
    }
</script>

<style>
    ul {
        margin-top: 50px
    }
    .marker {
      border-radius: 30px 0 0 30px;
      background-color: #EFF2FB;
      color: rgb(0,95,252);
      width: 100%;
      height: 55px;
      position: absolute;
      z-index: 1;
      transform: translateY(0px);
      transition: transform 0.25s;
      top: 147px;
      transition: top 0.2s;
    }

    /* .marker::after {
      content: '';
      border: solid 1px red;
    } */

    .marker::after {
        content: '';
        content: "";
        position: absolute;
        
        background-color: #005ffc;
        top: -85px;
        height: 85px;
        width: 47px;
        right: 20px;
        border-bottom-right-radius: 40px;
        box-shadow: 0 45px 0 0 #EFF2FB;
        user-select: none;
        cursor: default;
        transform: translateY(0px);
        transition: transform 0.4s;
    }
    .marker::before {
        content: attr(data-content);
        position: absolute;
        
        background-color: rgb(0,95,252);
        bottom: -85px;
        height: 85px;
        width: 47px;
        right: 20px;
        border-top-right-radius: 40px;
        box-shadow: 0 -45px 0 0 #EFF2FB;
        user-select: none;
        cursor: default;
        transform: translateY(0px);
        transition: transform 0.4s;
    }
</style>