<template>
  <div class="container">
    <h1>Change Role</h1>
    <change-user-table :key="componentKey"></change-user-table>
  </div>
</template>

<script>
  import ChangeUserTable from './ChangeUserTable.vue'
  export default {
    components: {
      ChangeUserTable
    },
    data() {
      return {
        componentKey: 0,
      }
    },
    watch: {
      $route(to) {
        // watch route changes. if the name is change-role, then re-render component
        if(to.name === 'change-role') {
          this.rerender();
        }
      }
    },
    methods: {
      rerender() {
        this.componentKey += 1;
      }
    }
  }
</script>