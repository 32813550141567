<template>
  <div>
    <div class="total__container">
      <base-total :total-sum="totalSum"></base-total>
      <base-button :custom-class="{'animate': changeArray.length !== 0, 'total__button': true}" @click="applyInsentif()">Apply</base-button>
    </div>
    <div class="msg__container">
      <p class="success" v-if="successMessage">Berhasil: {{successMessage}}</p>
      <p class="error" v-if="errorMessage">Error: {{errorMessage}}</p>
    </div>
    <div class="insentif__container">
        <div class="grid">
            <div class="cell__first timetable__header">
                <div class="cell cell__header">Nama</div>
            </div>
             <div class="timetable__header">
                <div class="cell cell__header">Insentif</div>
            </div>
             <div class="timetable__header">
                <div class="cell cell__header">Dibagikan?</div>
            </div>
        </div>
        <!-- Run method to calculate insentif -->
        <div class="grid" v-for="(user, i) in users" :key="i + this.thisSunday">
            <div class="cell cell__left" :class="{'terbagi': insentifDistributed.includes(user.id) || (insentifDistributed.some(e => e.userId === user.id && e.date === this.thisSunday))}">{{user.firstName}} {{user.lastName}}</div>

            <div class="cell" :class="{'terbagi': insentifDistributed.includes(user.id)  || (insentifDistributed.some(e => e.userId === user.id && e.date === this.thisSunday))}">$IDP {{new Intl.NumberFormat("id-ID", { minimumFractionDigits: 0 }).format(calculateInsentif(user.id, user.associatedRole.role))}}</div>

            <div class="cell" :class="{'terbagi': insentifDistributed.includes(user.id) || (insentifDistributed.some(e => e.userId === user.id && e.date === this.thisSunday))}">
              <input type="checkbox" :checked="insentifDistributed.includes(user.id) || (changeArray.some(e => e.userId === user.id && e.date === this.thisSunday)) || (insentifDistributed.some(e => e.userId === user.id && e.date === this.thisSunday))" :disabled="insentifDistributed.includes(user.id) || (insentifDistributed.some(e => e.userId === user.id && e.date === this.thisSunday)) || (totalChecks >= 7 && !changeArray.some(o => {return o.userId === user.id && o.date === this.thisSunday}))" @change="changeHandler($event, user.id, employeePaycheck[user.id], user.firstName + ' ' + user.lastName, user.roleId)" />
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import BaseTotal from '../UI/BaseTotal.vue'
    import moment from 'moment'
    export default {
        components: {
          BaseTotal
        },
        props: ['users', 'allTimeTable', 'thisSunday', 'thisMonday'],
        data() {
            return {
                employeePaycheck: {},
                changeArray: [],
                insentifDistributed: [],
                totalSum: 0,
                successMessage: '',
                errorMessage: '',
                totalChecks: 0,
            }  
        },
        watch: {
          async thisSunday() {
            this.insentifDistributed = [];
            await this.fetchInsentif();
          }
        },
        async mounted() {
          await this.fetchInsentif();
        },
        methods: {
          formatDate(date) {
              return moment(date).utc().format('DD/MM/YYYY')
            },
            changeHandler(e, userId, totalPaycheck, userName, userRole) {
              var role = '';
              switch(userRole) {
                case 0:
                 role = 'Part Time'
                 break;
                case 1:
                 role = 'Ekonomi'
                 break;
                case 2:
                 role = 'Premium'
                 break;
                case 3:
                 role = 'Bisnis'
                 break;
                case 4:
                 role = 'Eksekutif'
                 break;
                case 5:
                 role = 'Boss'
                 break;
                case 6:
                 role = 'CEO'
                 break;
                
              }
              if(e.target.checked) {
                this.totalChecks++
                this.totalSum += totalPaycheck
                const insentifObj = {date: this.thisSunday, userId, userName, totalPaycheck, role}
                this.changeArray.push(insentifObj)
              } else {
                this.totalChecks--
                this.totalSum -= totalPaycheck
                this.changeArray = this.changeArray.filter((el) => {
                  return el.userId !== userId || el.date !== this.thisSunday
                })
              }
            },
            async fetchInsentif() {
              await this.fetchData(`/insentif/find/${this.thisSunday}`, 'GET', null, true)
                .then(res => {
                  return res.json()
                })
                .then(data => {
                  for(let i = 0; i < data.insentif.length ; i++) {
                    const insentifObj = {
                      date: moment(data.insentif[i].date).utc().format("YYYY-MM-DD"),
                      userId: data.insentif[i].userId
                    }
                    this.insentifDistributed.push(insentifObj)
                  }
                })
            },
            getCurrentWeek() {
              const today = new Date(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}));
              var first 
              var last
              
              // check if today is Sunday to avoid miss calculation of timesheet into next week
              if(today.getDay() === 0) {
                first = today.getDate() - today.getDay() - 6;
                last = first + 6
              } else {
                first = today.getDate() - today.getDay() + 1;
                last = first + 6
              }
            
              let monday = new Date(new Date(today.setDate(first)));
              let sunday = new Date(new Date(today.setDate(last)));
            
              if(sunday < monday) {
                sunday = new Date(sunday.setMonth(sunday.getMonth() + 1))
              }
              if(sunday.getFullYear() > monday.getFullYear()) {
                sunday = new Date(sunday.setFullYear(sunday.getFullYear() + 1))
              }
            
              return {monday, sunday};
            },
            async applyInsentif() {
              const newArray = this.changeArray.sort(function(a,b){
                return a.date.localeCompare(b.date);
              })
              await this.fetchData('/discord/post-insentif', 'POST', JSON.stringify({changeArray: newArray, totalInsentif: this.totalSum, dateMonday: this.thisMonday}), true)
                
              await this.fetchData('/insentif/post-insentif', 'POST', JSON.stringify({changeArray: this.changeArray}), true)
                .then(res => {
                  return res.json();
                })
                .then(data => {
                  if(data.status === 'Berhasil') {
                    this.successMessage = data.msg
                    setTimeout(() => {
                      this.successMessage = ''
                    }, 5000)
                    this.insentifDistributed = [...this.insentifDistributed, ...this.changeArray];

                    // reset value
                    this.changeArray = []
                    this.totalSum = 0;
                    this.totalChecks = 0;
                  } else {
                    this.errorMessage = data.msg
                  }
                })
            },
            calculateInsentif(userId, role) {
                // check if role is boss, if so just immediately return $IDP 500.000
                if(role === 'Boss') {
                    this.employeePaycheck[userId] = 500000
                    return 500000
                }
                // get multiplier
                var multiplier = 0;
                switch(role) {
                    case 'Part Time':
                      multiplier = 0;
                      break;
                    case 'Ekonomi':
                        multiplier = 20000;
                    break;
                    case 'Premium':
                        multiplier = 30000;
                    break;
                    case 'Bisnis':
                        multiplier = 35000;
                    break;
                    case 'Eksekutif':
                        multiplier = 35000;
                    break;
                }

                // get user based on userId
                var newArr = this.allTimeTable.filter(function(el) {
                    return el.userId === userId
                })


                //control variable
                let dailyHour = 0;
                let gajiCounted = false;
                let totalNumber = 0;
                let overtimeExceed = false;
                let countedOvertime = 0;
                let runningCounter = 0;

                // check if today is sunday, then automatically add gaji
                if(this.checkSunday(moment(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}), 'MM/DD/YYYY').format('YYYY-MM-DD')) || moment(this.thisSunday, "YYYY-MM-DD").isBefore(moment(new Date().toLocaleDateString('en-US', {timeZone: "Asia/Jakarta"}), 'MM/DD/YYYY').format('YYYY-MM-DD'), 'day')) {
                  totalNumber += 1
                }

                // this for loop is to count for days that's not sunday based on employees time
                for(let i = 0; i < newArr.length; i++) {
                    if(i === 0 || this.formatDate(newArr[i].associatedTimesheet.date) !== this.formatDate(newArr[i-1].associatedTimesheet.date)) {
                      dailyHour = newArr[i].associatedTimesheet.hours

                      // we add overtime with running counter before we reset running counter below
                      // we add it up top because we have a if check down below to add it in the case that the first item is considered overtime.
                      countedOvertime += runningCounter;


                      // reset control variable
                      gajiCounted = false;
                      overtimeExceed = false;
                      runningCounter = 0;

                      // logic to count base insetif
                      if(parseInt(newArr[i].associatedTimesheet.hours) >= 3 && !this.checkSunday(newArr[i].associatedTimesheet.date)) {
                        totalNumber += 1
                        gajiCounted = true
                      }
                      /*
                        Logic to count overtime:
                        - max value is 9. if its more than 9, we don't calculate overtime and add max value to running counter (3)
                        - if its less than 5, since we reset it above, we just continue
                        - if it is more than 5, we add it to running counter and continue through the loop
                      */
                      if(newArr[i].associatedTimesheet.hours > 9.0) {
                        overtimeExceed = true;
                        runningCounter = 3;
                      } else if(newArr[i].associatedTimesheet.hours < 5.0) {
                        continue
                      } else {
                        runningCounter = parseInt((dailyHour - 3) / 2)
                      }
                    }
                    else if(this.formatDate(newArr[i].associatedTimesheet.date) === this.formatDate(newArr[i-1].associatedTimesheet.date) && !this.checkSunday(newArr[i].associatedTimesheet.date)) {
                        dailyHour += newArr[i].associatedTimesheet.hours

                        // if total daily hour exceed 9 hours, then we'll stop counting extra time. We check here because this is the running sum
                        if(parseInt(dailyHour) >= 3 && !gajiCounted) {
                          totalNumber += 1
                          gajiCounted = true;
                        }
                        if(!overtimeExceed) {
                          // if daily hour is 9 hour and overtime has been counted, we continue
                          // The countedOvertime check is to prevent overtime not calculated. For example, if overtime counted is only 2, but the total hours for the day jump from 7 to 9.5, we should count that in
                          if(dailyHour > 9.0) {
                            overtimeExceed = true;
                            runningCounter = 3
                          } 
                          // count overtime
                          else if(dailyHour < 5.0) {
                            continue
                          } else {
                            runningCounter = parseInt((dailyHour - 3) / 2)
                          }
                        }
                    }
                    // check if its the last item in the array. if it is, then add the running counter. 
                    if(i === newArr.length - 1) {
                      countedOvertime += runningCounter
                    }
                }

                // store each employee paycheck to an object with userId being the key and total insentif as the value
                // this is used to access when admin check on insentif to return the total dollar value based on the userId
                if(userId === '660abe9e-692d-45d4-b1f0-78f146f23b52' || userId === '2e077815-2d61-4aa0-baa4-306c2e0a26bf' || userId === 'e8738dbf-2c72-41f0-a389-36fe7df2c38f' || userId === 'ba58f3dd-f9f4-4621-847c-a648d8eeac02' || userId === '75d3a72a-2540-4223-b27e-9b193b7e609d' || userId === '07ad27f8-0294-4b9d-b7f9-d4257b0ffcaf' || userId === '00396799-b87c-47ff-b67b-4287033bdfc1') {
                  this.employeePaycheck[userId] = totalNumber * multiplier + (countedOvertime * 0.5 * multiplier) + 200000
                  return (totalNumber * multiplier) + (countedOvertime * 0.5 * multiplier) + 200000
                } else if( userId === '96e773e5-6c30-408e-9808-7d7df3d776f2' || userId === '28e597cf-ebfd-41ab-95c2-c5477b11ba1b'  || userId === 'fc799143-55e8-4725-9113-788293dcb026' || userId === '8a512ce2-c53f-45a3-bcfd-08669cfbe54c' || userId === '8dcbd56c-d6ef-472c-8b8c-407f0398d2e0' || userId === '58274ef2-b4f0-4929-91d7-a4199b911c83' || userId === '18855034-7024-4b48-8890-68413a0801ac' ||
                userId === '9d02446a-7c9c-4fc8-9553-2f8e71e38802' ||
                userId === '5cf3a9b1-43a7-4229-86dd-95ebad0be46f' ||
                userId === '9eec3bf4-bd09-4896-bc3c-9a9c7af90e6e' ||
                userId === '0b1f18a4-750a-4fe2-a90c-5e3c928b023d' || userId === '87f34017-628c-4aef-a141-e94ea185747e' || userId === '62086318-9da5-43bd-9672-ded65f3ddd0c' || userId === '6d7228ce-9949-45bf-8c75-17f80517890e' || userId === '2022cbc9-14e2-4f60-83c8-1a2c64778714') {
                  this.employeePaycheck[userId] = totalNumber * multiplier + (countedOvertime * 0.5 * multiplier) + 150000
                  return (totalNumber * multiplier) + (countedOvertime * 0.5 * multiplier) + 150000
                } else if (userId === '296d0bc6-7bcc-495c-a69d-de1b005aa716' || userId === 'cff09594-77c9-429d-8273-da520b358744') {
                  this.employeePaycheck[userId] = totalNumber * multiplier + (countedOvertime * 0.5 * multiplier) + 50000
                  return (totalNumber * multiplier) + (countedOvertime * 0.5 * multiplier) + 50000
                } else {
                  this.employeePaycheck[userId] = totalNumber * multiplier + (countedOvertime * 0.5 * multiplier)
                  // return totalNumber and multiplier
                  return (totalNumber * multiplier) + (countedOvertime * 0.5 * multiplier)
                }
                
            },
            checkSunday(date) {
                return moment(date).day() === 0 ? true : false
            },
        }, 
        inject: ['fetchData', 'readjustDate']
    }
</script>

<style scoped>
    .insentif__container {
        margin-top: 20px;
    }
    p {
        text-align: left;
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    .cell__left {
        border-left: solid 1px black
    }
    .total__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      min-height: 40px;
    }

    .terbagi {
      background-color: rgba(2, 146, 2, 0.26)
    }
</style>